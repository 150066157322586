import cs from 'classnames';

import styles from './styles.module.scss';

interface MixedGradientProps {
  className?: string;
}

const MixedGradient = ({ className }: MixedGradientProps) => {
  return (
    <div className={cs(['duration-2000 transition-all', styles.container, className])}>
      <div className={cs([styles.g, styles.g1])} />
      <div className={cs([styles.g, styles.g2])} />
    </div>
  );
};

export default MixedGradient;
