import { useThree } from '@react-three/fiber';
import gsap from 'gsap';
import { useEffect } from 'react';
import { BackSide, Mesh, ShaderMaterial, SphereGeometry, UniformsUtils, Vector3 } from 'three';
import * as THREE from 'three';
import { vertexShaderSky, fragmentShaderSky } from '../shaders';

import { day, night } from '../utils';

class SkyRaw extends Mesh {
  constructor() {
    const shader = SkyRaw.SkyShader;

    const material = new ShaderMaterial({
      name: 'SkyShader',
      fragmentShader: shader.fragmentShader,
      vertexShader: shader.vertexShader,
      uniforms: UniformsUtils.clone(shader.uniforms),
      side: BackSide,
      depthWrite: false,
    });

    super(new SphereGeometry(1, 16, 4), material);

    this.isSky = true;
  }
}

SkyRaw.SkyShader = {
  uniforms: {
    turbidity: { value: 10 },
    rayleigh: { value: 4 },
    mieCoefficient: { value: 0 },
    mieDirectionalG: { value: 0.7 },
    sunPosition: { value: new Vector3() },
    up: { value: new Vector3(0, 1, 0) },
    night: { value: 1.0 },
    colorsDay: day.colors,
    stepsDay: day.steps,
    colorsNight: night.colors,
    stepsNight: night.steps,
  },
  vertexShader: vertexShaderSky,
  fragmentShader: fragmentShaderSky,
};

let sun, renderTarget, lava, pmremGenerator;
const sky = new SkyRaw();
sky.scale.setScalar(450000);
sky.layers.enable(1);

const Sky = ({ mode, setSkyObject }) => {
  const { scene, gl } = useThree();
  sun = new THREE.Vector3();
  pmremGenerator = new THREE.PMREMGenerator(gl);

  useEffect(() => {
    function setSky(value) {
      const lerp = (x, y, a) => x * (1 - a) + y * a;
      const uniforms = sky.material.uniforms;

      if (value === undefined) {
        value = uniforms.night.value;
      }

      uniforms.night.value = value;

      const elevation = lerp(2, -15, value);
      const phi = THREE.MathUtils.degToRad(90 - elevation);
      const theta = THREE.MathUtils.degToRad(-50);

      sun.setFromSphericalCoords(1, phi, theta);
      uniforms.sunPosition.value.copy(sun);

      if (renderTarget !== undefined) renderTarget.dispose();
      renderTarget = pmremGenerator.fromScene(sky);
      scene.environment = renderTarget.texture;

      return value;
    }

    scene.add(sky);
    setSkyObject(sky);
    if (window) {
      window.setSky = {
        value: (progress) => setSky(progress),
      };
    }
  }, [scene, setSkyObject]);

  useEffect(() => {
    gsap.to(window.setSky, {
      duration: 2,
      value: mode === 'day' ? 0.0 : 1.0,
    });
  }, [mode]);

  return <></>;
};

export { Sky };
