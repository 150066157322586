import { Color } from 'three';

export const lerp = (x: number, y: number, a: number) => x * (1 - a) + y * a;
export const clamp = (a: number, min = 0, max = 1) => Math.min(max, Math.max(min, a));
export const invlerp = (x: number, y: number, a: number) => clamp((a - x) / (y - x));

export const gradient = (str: string) => {
  const item = str.split(',');

  const res = item.map((el) => {
    return el.split(' ').filter((el) => el !== '');
  });

  if (res.length < 10) {
    const eI = res.length - 1;
    const n = 10 - res.length;
    for (let i = 0; i < n; i++) {
      res.push(res[eI]);
    }
  }

  return {
    colors: {
      value: res.map((el) => {
        return new Color(el[0]);
      }),
    },
    steps: {
      value: res.map((el) => {
        return parseFloat(el[1].replace('%', '')) / 100;
      }),
    },
  };
};

export const day = gradient(
  '#1C61F6 0.01%, #8E65D8 36.17%, #DB8796 45.05%, #E9B299 49.06%, #AE6676 54.72%, #40244D 54.98%, #010000 64.48%, #010000 100%',
);
export const night = gradient('#000F26 0%, #000226 35.48%, #1A283E 49.72%, #000F26 49.9%, #000F26 100%');
