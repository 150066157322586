import { useTexture } from '@react-three/drei';
import type { Object3DNode } from '@react-three/fiber';
import { extend, useFrame, useLoader, useThree } from '@react-three/fiber';
import { Water } from 'components/Water';
import gsap from 'gsap';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import type { ShaderMaterial } from 'three';
import { TextureLoader, Vector3 } from 'three';
import * as THREE from 'three';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import { lights } from '../lights';
import {
  fragmentShaderLand,
  fragmentShaderSky,
  fragmentShaderVolcano,
  vertexShaderLand,
  vertexShaderSky,
  vertexShaderVolcano,
} from '../shaders';
import { day, invlerp, lerp, night } from '../utils';
import { Fire } from './Fire';
import { Sky } from './Sky';

extend({ Water });

declare global {
  // eslint-disable-next-line
  namespace JSX {
    interface IntrinsicElements {
      water: Object3DNode<Water, typeof Water>;
    }
  }
}

interface IslandProps {
  mode: 'day' | 'night';
  muted: boolean;
  scrollProgress: number;
  visibleIsland: boolean;
}

const Island = ({ mode, muted, scrollProgress, visibleIsland }: IslandProps) => {
  const group = useRef<any>();
  const group2 = useRef<any>();
  const [scene2, setScene2] = useState<THREE.Scene>(new THREE.Scene());
  const mainBoatGroupRef = useRef<any>();
  const mainBoatGroupRef2 = useRef<any>();
  const skyRef = useRef<THREE.Mesh>(null!) as React.MutableRefObject<THREE.Mesh>;
  const waterRef = useRef<Water>(null!) as React.MutableRefObject<Water>;
  const [, setSkyObject] = useState<THREE.Mesh>(null!);
  const [lava, setLava] = useState<THREE.Mesh>(null!);
  const shaderMaterialLandRef = useRef<ShaderMaterial>(null!) as React.MutableRefObject<ShaderMaterial>;
  const shaderMaterialVolcanoRef = useRef<ShaderMaterial>(null!) as React.MutableRefObject<ShaderMaterial>;
  const [lightsRefs] = useState<(THREE.PointLight | null)[]>([]);
  const { camera, gl, scene } = useThree();
  const fps = 29;

  gl.toneMapping = THREE.NoToneMapping;

  const tDayTexture = useTexture('/images/land-day.jpg');
  tDayTexture.flipY = false;
  const tNightTexture = useTexture('/images/land-night.jpg');
  tNightTexture.flipY = false;

  const { scene: islandScene } = useLoader(GLTFLoader, '/3d/island.gltf', (loader) => {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('/3d/draco/');
    loader.setDRACOLoader(dracoLoader);
  });
  const waterNormals = useLoader(TextureLoader, '/images/waternormals.jpeg');
  waterNormals.wrapS = waterNormals.wrapT = THREE.RepeatWrapping;
  const boatNormals = useLoader(TextureLoader, '/images/boat.jpg');
  boatNormals.wrapS = boatNormals.wrapT = THREE.RepeatWrapping;
  const boatMask = useLoader(TextureLoader, '/images/boat-mask.jpg');
  boatMask.wrapS = boatMask.wrapT = THREE.RepeatWrapping;
  const geom = useMemo(() => new THREE.PlaneGeometry(10000, 10000), []);
  const waterConfig = useMemo(
    () => ({
      textureWidth: 512,
      textureHeight: 512,
      waterNormals,
      boatNormals,
      boatMask,
      alpha: 1.0,
      sunColor: 0xffdd9b,
      sunDirection: new THREE.Vector3(),
      waterColor: 0x32b1e7,
      distortionScale: 1,
      renderer: gl,
      camera,
      scene,
    }),
    [waterNormals, boatNormals, boatMask],
  );

  useEffect(() => {
    gsap.to(shaderMaterialLandRef.current.uniforms.opacity, {
      value: mode === 'day' ? 0.0 : 1.0,
      duration: 1,
    });
    lightsRefs.forEach((light, i) => {
      if (light) {
        gsap.to(light, {
          intensity: mode === 'day' ? 0.0 : lights[i].initialIntensity,
          duration: 1,
        });
      }
    });
  }, [mode, lightsRefs]);

  useEffect(() => {
    if (waterRef.current) {
      waterRef.current.renderer = gl;
      waterRef.current.camera = camera;
      waterRef.current.scene = scene;
      (waterRef.current.material as ShaderMaterial).uniforms.size.value = 1;
    }
  }, [scene, waterRef.current]);

  useEffect(() => {
    if (scene.children.length === 8 && scene2.children.length < 1) {
      const sc2 = new THREE.Scene();
      sc2.environment = scene.environment;
      sc2.add(scene.children[0].clone());
      sc2.add(scene.children[5].clone());
      sc2.add(group2.current);
      console.log(mainBoatGroupRef2.current.position);
      setScene2(sc2);
    }
  }, [scene, visibleIsland]);

  useFrame(() => {
    if (waterRef.current) {
      (waterRef.current.material as ShaderMaterial).uniforms.time.value += 1.0 / 560.0;
      waterRef.current.update(fps < 30 ? scene2 : scene);
      waterRef.current.position.y = -2.4085302352905273 - 0.5;
      waterRef.current.rotation.x = -Math.PI / 2;
    }
    if (lava) (lava.material as ShaderMaterial).uniforms.time.value += 1.0 / 50.0;
  });

  const shader = useMemo(
    () => ({
      uniforms: {
        turbidity: { value: 10 },
        rayleigh: { value: 4 },
        mieCoefficient: { value: 0 },
        mieDirectionalG: { value: 0.7 },
        sunPosition: { value: new THREE.Vector3() },
        up: { value: new THREE.Vector3(0, 1, 0) },
        night: { value: 1.0 },
        colorsDay: day.colors,
        stepsDay: day.steps,
        colorsNight: night.colors,
        stepsNight: night.steps,
      },
    }),
    [],
  );

  const skyMaterial = useMemo(
    () => ({
      uniforms: {
        turbidity: { value: 10 },
        rayleigh: { value: 4 },
        mieCoefficient: { value: 0 },
        mieDirectionalG: { value: 0.7 },
        sunPosition: { value: new Vector3().setFromSphericalCoords(1, Math.PI / 2, 0) },
        up: { value: new Vector3(0, 1, 0) },
        night: { value: 1.0 },
        colorsDay: day.colors,
        stepsDay: day.steps,
        colorsNight: night.colors,
        stepsNight: night.steps,
      },
      vertexShader: vertexShaderSky,
      fragmentShader: fragmentShaderSky,
      side: THREE.BackSide,
      depthWrite: false,
    }),
    [],
  );

  const geometrySky = new THREE.SphereGeometry(1, 16, 4);
  const materialSky = new THREE.ShaderMaterial({
    name: 'SkyShader',
    fragmentShader: skyMaterial.fragmentShader,
    vertexShader: skyMaterial.vertexShader,
    uniforms: THREE.UniformsUtils.clone(shader.uniforms),
    side: THREE.BackSide,
    depthWrite: false,
  });

  const landMaterial = useMemo(
    () => ({
      attributes: {},
      uniforms: {
        opacity: { value: 1.0 },
        tDay: { value: tDayTexture, type: 't' },
        tNight: { value: tNightTexture, type: 't' },
      },
      vertexShader: vertexShaderLand,
      fragmentShader: fragmentShaderLand,
    }),
    [],
  );

  const volcanoMaterial = useMemo(
    () => ({
      uniforms: {
        time: { value: 1.0 },
        resolution: { value: new THREE.Vector2() },
        viewVector: { value: new THREE.Vector3() },
      },
      vertexShader: vertexShaderVolcano,
      fragmentShader: fragmentShaderVolcano,
      side: THREE.FrontSide,
    }),
    [],
  );

  useEffect(() => {
    if (islandScene && shaderMaterialLandRef.current) {
      islandScene.traverse((child) => {
        if (child instanceof THREE.Mesh && child.name === 'Land' && shaderMaterialLandRef.current) {
          child.material = shaderMaterialLandRef.current;
        }
      });
    }
  }, [islandScene, shaderMaterialLandRef]);

  useEffect(() => {
    let boatProgress = invlerp(0, 0.22, scrollProgress);
    let boatRotationY = lerp(-0.9236617375488925, -0.3076429801993352, boatProgress);
    if (scrollProgress >= 0.3) {
      boatProgress = invlerp(0.22, 1, scrollProgress);
      boatRotationY = lerp(-0.3076429801993352, -0.9236617375488925, boatProgress);
    }
    if (mainBoatGroupRef.current) mainBoatGroupRef.current.rotation.y = boatRotationY;
    if (mainBoatGroupRef2.current) mainBoatGroupRef2.current.rotation.y = boatRotationY;
  }, [scrollProgress]);

  useEffect(() => {
    if (islandScene) {
      islandScene.traverse((child) => {
        if (
          group2.current &&
          child instanceof THREE.Mesh &&
          (['volcano', 'Land', 'Cylinder003', 'lava'].includes(child.name) || child.name.startsWith('retopo_boat'))
        ) {
          group2.current.add(child.clone());
        }

        if (child instanceof THREE.Mesh && child.name === 'Land') {
          child.material = shaderMaterialLandRef.current;
        }

        if (child instanceof THREE.Mesh && child.name === 'lava') {
          child.material = shaderMaterialVolcanoRef.current;
          setLava(child);
        }

        if (
          child instanceof THREE.Mesh &&
          (child.name.startsWith('Palm') || child.name.startsWith('rock') || child.name.startsWith('pebble'))
        ) {
          child.material = new THREE.MeshStandardMaterial({
            map: child.material.map,
            roughness: child.material.roughness,
          });
        }

        if (['main_boat'].includes(child.name)) {
          if (mainBoatGroupRef2.current) {
            mainBoatGroupRef2.current.add(child.clone());
          }
        }

        if (child.name.startsWith('main_boat')) {
          if (mainBoatGroupRef.current) {
            mainBoatGroupRef.current.add(child.clone());
          }
          child.visible = false;
        }

        if (child.name === 'SHOP_NEON') {
          child.traverse((item) => {
            if (item instanceof THREE.Mesh) item.material = new THREE.MeshBasicMaterial({ color: 'yellow' });
          });
        }
      });
    }
  }, [
    islandScene,
    group,
    group2,
    mainBoatGroupRef,
    mainBoatGroupRef2,
    shaderMaterialLandRef,
    shaderMaterialVolcanoRef,
  ]);

  return (
    <>
      <Sky mode={mode} setSkyObject={setSkyObject} />
      <Fire muted={muted} />
      {/* TODO: {visibleIsland && !muted && <Audio />} */}
      <group ref={group} rotation={[0, 2 * Math.PI * (180 / 360), 0]}>
        <primitive object={islandScene} />
        <group>
          {lights.slice(0, lights.length - 1).map((light, idx) => (
            <pointLight
              ref={(ref) => (lightsRefs[idx] = ref)}
              key={idx}
              distance={light.distance}
              color={light.color}
              intensity={light.intensity}
              position={light.position}
            />
          ))}
        </group>
        <group
          ref={mainBoatGroupRef}
          position={[-16.424150557393105, 0.4561586964856872, 41.06292937799704]}
          rotation={[0, -0.9236617375488925, 0]}
        >
          <water ref={waterRef} args={[geom, waterConfig]} position={[0, -2.4085302352905273, 0]} />
          {mode === 'night' && (
            <pointLight
              ref={(ref) => (lightsRefs[lights.length - 1] = ref)}
              key={lights.length}
              distance={lights[lights.length - 1].distance}
              color={lights[lights.length - 1].color}
              intensity={lights[lights.length - 1].intensity}
              position={lights[lights.length - 1].position}
            />
          )}
        </group>
      </group>
      <group ref={group2} rotation={[0, 2 * Math.PI * (180 / 360), 0]}>
        <group
          ref={mainBoatGroupRef2}
          position={[-16.424150557393105, 0.4561586964856872, 41.06292937799704]}
          rotation={[0, -0.9236617375488925, 0]}
        >
          {mode === 'night' && (
            <pointLight
              ref={(ref) => (lightsRefs[lights.length - 1] = ref)}
              key={lights.length}
              distance={lights[lights.length - 1].distance}
              color={lights[lights.length - 1].color}
              intensity={lights[lights.length - 1].intensity}
              position={lights[lights.length - 1].position}
            />
          )}
        </group>
      </group>
      <mesh ref={skyRef} geometry={geometrySky} material={materialSky} />
      <shaderMaterial ref={shaderMaterialLandRef} attach='material' {...landMaterial} />
      <shaderMaterial ref={shaderMaterialVolcanoRef} attach='material' {...volcanoMaterial} />
    </>
  );
};

export default Island;
