import cs from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

import Button from '@/components/Button';
import { useAnalytics } from '@/hooks/useAnalytics';

import styles from './styles.module.scss';

interface MenuProps {
  mode: 'day' | 'night';
  className?: string;
  menuOpened: boolean;
  setIsMenuOpen: (isOpen: boolean) => void;
  setIsPanelOpen?: (isOpen: boolean) => void;
  setWaitlistOpen?: (isOpen: boolean) => void;
  lenis?: any;
}

const Menu = ({ mode, menuOpened, setIsMenuOpen, className, lenis }: MenuProps) => {
  const analytics = useAnalytics();
  const router = useRouter();
  return (
    <div className={className}>
      <Button
        outline
        darkMode={mode === 'day'}
        className={cs([`ml-2 tablet:ml-5 ${styles[mode]}`, menuOpened ? styles.open : ''])}
        onClick={() => setIsMenuOpen(!menuOpened)}
        icon={
          menuOpened ? (
            <svg width='20' height='20' viewBox='0 0 20 20' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M12.1214 10L19.546 2.57542L17.4247 0.454102L10 7.87872L2.57542 0.454102L0.454102 2.57542L7.87872 10L0.454102 17.4247L2.57542 19.546L10 12.1214L17.4247 19.546L19.546 17.4247L12.1214 10Z'
                fill='currentColor'
              />
            </svg>
          ) : (
            <svg width='22' height='9' viewBox='0 0 22 9' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M1.85436e-08 9L22 9L22 6.75L1.39077e-08 6.75L1.85436e-08 9ZM2.26644e-09 0L6.90234e-09 2.25L22 2.25L22 -4.53288e-08L2.26644e-09 0Z'
                fill='currentColor'
              />
            </svg>
          )
        }
      />
      <ul
        className={cs(
          `absolute flex flex-col left-[12px] tablet:left-[inherit] w-[calc(100vw-24px)] top-[16px] tablet:w-[252px] rounded-[28px] justify-center transition duration-200 ease-in-out
          mobile:top-[80px] desktop:top-[104px] mobile:right-[12px] desktop:right-[60px] desktopl:right-[80px]
          ${mode === 'day' ? 'bg-black text-white' : 'bg-white text-black'}
          ${menuOpened ? 'opacity-100 z-30' : 'opacity-0 -top-[200vh] pointer-events-none'}`,
        )}
      >
        <li
          className={cs([
            `transition w-full leading-none h-[56px] cursor-pointer font-medium ${styles[mode]}`,
            styles.item,
          ])}
        >
          <button
            className='w-full py-[19px] pl-5 text-left'
            onClick={() => {
              if (lenis) lenis.destroy();
              document.body.style.removeProperty('height');
              document.body.style.removeProperty('overflow');
              router.push('/contact-us');
            }}
          >
            Get in touch
          </button>
        </li>
        <li className={`${styles.item} ${styles[mode]}`}>
          <Link
            href='https://wytland.medium.com/wytland-manifesto-f877c9b267a5'
            onClick={() => {
              analytics.track('Click', {
                source: 'menu',
                key: 'website_manifesto_cta_tapped',
              });
            }}
            target={'_blank'}
            className={cs([
              `flex justify-between transition leading-none cursor-pointer font-medium pb-[19px] px-5 pt-[19px] w-full`,
            ])}
          >
            Our Vision
            <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M16 8.82C15.7348 8.82 15.4804 8.92536 15.2929 9.11289C15.1054 9.30043 15 9.55478 15 9.82V17C15 17.2652 14.8946 17.5196 14.7071 17.7071C14.5196 17.8946 14.2652 18 14 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V6C2 5.73478 2.10536 5.48043 2.29289 5.29289C2.48043 5.10536 2.73478 5 3 5H10.18C10.4452 5 10.6996 4.89464 10.8871 4.70711C11.0746 4.51957 11.18 4.26522 11.18 4C11.18 3.73478 11.0746 3.48043 10.8871 3.29289C10.6996 3.10536 10.4452 3 10.18 3H3C2.20435 3 1.44129 3.31607 0.87868 3.87868C0.316071 4.44129 0 5.20435 0 6V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H14C14.7956 20 15.5587 19.6839 16.1213 19.1213C16.6839 18.5587 17 17.7956 17 17V9.82C17 9.55478 16.8946 9.30043 16.7071 9.11289C16.5196 8.92536 16.2652 8.82 16 8.82ZM19.92 0.62C19.8185 0.375651 19.6243 0.181475 19.38 0.0799999C19.2598 0.028759 19.1307 0.00157999 19 0H13C12.7348 0 12.4804 0.105357 12.2929 0.292893C12.1054 0.48043 12 0.734784 12 1C12 1.26522 12.1054 1.51957 12.2929 1.70711C12.4804 1.89464 12.7348 2 13 2H16.59L6.29 12.29C6.19627 12.383 6.12188 12.4936 6.07111 12.6154C6.02034 12.7373 5.9942 12.868 5.9942 13C5.9942 13.132 6.02034 13.2627 6.07111 13.3846C6.12188 13.5064 6.19627 13.617 6.29 13.71C6.38296 13.8037 6.49356 13.8781 6.61542 13.9289C6.73728 13.9797 6.86799 14.0058 7 14.0058C7.13201 14.0058 7.26272 13.9797 7.38458 13.9289C7.50644 13.8781 7.61704 13.8037 7.71 13.71L18 3.41V7C18 7.26522 18.1054 7.51957 18.2929 7.70711C18.4804 7.89464 18.7348 8 19 8C19.2652 8 19.5196 7.89464 19.7071 7.70711C19.8946 7.51957 20 7.26522 20 7V1C19.9984 0.869323 19.9712 0.740222 19.92 0.62Z'
                fill='currentColor'
              />
            </svg>
          </Link>
        </li>
        <li
          className={cs([
            `transition w-full leading-none h-[56px] cursor-pointer font-medium tablet:hidden ${styles[mode]}`,
            styles.item,
          ])}
        >
          <Link
            href='https://discord.gg/mtUhx3PRCN'
            target={'_blank'}
            className='flex w-full justify-between px-5 py-[19px]'
            onClick={() =>
              analytics.track('Discord community cta tapped', { key: 'website_discord_cta_tapped', source: 'menu' })
            }
          >
            <span className='flex'>
              <svg
                className='mr-[17px]'
                width='22'
                height='17'
                viewBox='0 0 22 17'
                fill='currentColor'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M18.6361 1.42372C17.1907 0.74903 15.6648 0.27038 14.0973 -2.90461e-08C13.8828 0.39093 13.6888 0.793144 13.5159 1.20496C11.8463 0.948447 10.1484 0.948447 8.47881 1.20496C8.30587 0.793187 8.1118 0.390977 7.8974 -1.62718e-08C6.32897 0.272663 4.80205 0.75245 3.35518 1.42725C0.482757 5.76017 -0.29591 9.98547 0.0934235 14.1508C1.77558 15.418 3.6584 16.3817 5.66003 17C6.11074 16.382 6.50956 15.7263 6.85226 15.04C6.20135 14.7921 5.57311 14.4863 4.9748 14.1261C5.13227 14.0097 5.28627 13.8897 5.43508 13.7732C7.17601 14.608 9.07613 15.0408 11 15.0408C12.9238 15.0408 14.8239 14.608 16.5648 13.7732C16.7154 13.8985 16.8694 14.0185 17.0251 14.1261C16.4257 14.4869 15.7963 14.7933 15.1442 15.0417C15.4865 15.7277 15.8853 16.3829 16.3364 17C18.3398 16.3841 20.224 15.4209 21.9065 14.1526C22.3633 9.32213 21.1261 5.13564 18.6361 1.42372ZM7.34541 11.5891C6.26047 11.5891 5.36414 10.5853 5.36414 9.35035C5.36414 8.1154 6.22932 7.10274 7.34195 7.10274C8.45458 7.10274 9.344 8.1154 9.32496 9.35035C9.30593 10.5853 8.45112 11.5891 7.34541 11.5891ZM14.6545 11.5891C13.5678 11.5891 12.675 10.5853 12.675 9.35035C12.675 8.1154 13.5401 7.10274 14.6545 7.10274C15.7689 7.10274 16.6514 8.1154 16.6323 9.35035C16.6133 10.5853 15.7602 11.5891 14.6545 11.5891Z'
                  fill='currentColor'
                />
              </svg>
              Discord
            </span>
            <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M16 8.82C15.7348 8.82 15.4804 8.92536 15.2929 9.11289C15.1054 9.30043 15 9.55478 15 9.82V17C15 17.2652 14.8946 17.5196 14.7071 17.7071C14.5196 17.8946 14.2652 18 14 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V6C2 5.73478 2.10536 5.48043 2.29289 5.29289C2.48043 5.10536 2.73478 5 3 5H10.18C10.4452 5 10.6996 4.89464 10.8871 4.70711C11.0746 4.51957 11.18 4.26522 11.18 4C11.18 3.73478 11.0746 3.48043 10.8871 3.29289C10.6996 3.10536 10.4452 3 10.18 3H3C2.20435 3 1.44129 3.31607 0.87868 3.87868C0.316071 4.44129 0 5.20435 0 6V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H14C14.7956 20 15.5587 19.6839 16.1213 19.1213C16.6839 18.5587 17 17.7956 17 17V9.82C17 9.55478 16.8946 9.30043 16.7071 9.11289C16.5196 8.92536 16.2652 8.82 16 8.82ZM19.92 0.62C19.8185 0.375651 19.6243 0.181475 19.38 0.0799999C19.2598 0.028759 19.1307 0.00157999 19 0H13C12.7348 0 12.4804 0.105357 12.2929 0.292893C12.1054 0.48043 12 0.734784 12 1C12 1.26522 12.1054 1.51957 12.2929 1.70711C12.4804 1.89464 12.7348 2 13 2H16.59L6.29 12.29C6.19627 12.383 6.12188 12.4936 6.07111 12.6154C6.02034 12.7373 5.9942 12.868 5.9942 13C5.9942 13.132 6.02034 13.2627 6.07111 13.3846C6.12188 13.5064 6.19627 13.617 6.29 13.71C6.38296 13.8037 6.49356 13.8781 6.61542 13.9289C6.73728 13.9797 6.86799 14.0058 7 14.0058C7.13201 14.0058 7.26272 13.9797 7.38458 13.9289C7.50644 13.8781 7.61704 13.8037 7.71 13.71L18 3.41V7C18 7.26522 18.1054 7.51957 18.2929 7.70711C18.4804 7.89464 18.7348 8 19 8C19.2652 8 19.5196 7.89464 19.7071 7.70711C19.8946 7.51957 20 7.26522 20 7V1C19.9984 0.869323 19.9712 0.740222 19.92 0.62Z'
                fill='currentColor'
              />
            </svg>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Menu;
