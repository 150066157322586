interface WytlandIconProps {
  className?: string;
}

export const WytlandIcon = ({ className }: WytlandIconProps) => (
  <svg
    className={className}
    width='42'
    height='24'
    viewBox='0 0 42 24'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M40.2079 0H1.79204C0.413037 0 -0.447804 1.50296 0.244285 2.70293L12.0145 23.1035C12.704 24.2988 14.4205 24.2988 15.11 23.1035L19.9613 14.6954C20.4225 13.8958 19.849 12.8936 18.9297 12.8936H8.19528C7.27595 12.8936 6.70188 11.8915 7.16362 11.0918L12.5305 1.78825C12.9902 0.991215 14.1347 0.991215 14.5944 1.78825L26.8905 23.103C27.58 24.2983 29.2965 24.2983 29.986 23.103L34.8373 14.6949C35.2986 13.8952 34.725 12.8931 33.8057 12.8931H23.0708C22.1572 12.8931 21.5857 11.9039 22.0314 11.1064C22.0319 11.1059 22.0324 11.1054 22.0329 11.1043L27.4061 1.78825C27.8657 0.991215 29.0102 0.991215 29.4699 1.78825L34.8446 11.1043C35.3043 11.9013 36.4488 11.9013 36.9084 11.1043L41.7556 2.70293C42.4477 1.50296 41.5874 0 40.2079 0Z'
      fill='currentColor'
    />
  </svg>
);
