export const vertexShaderSky = `
  #ifdef GL_ES
precision highp float;
#endif

uniform vec3 sunPosition;
uniform float rayleigh;
uniform float turbidity;
uniform float mieCoefficient;
uniform vec3 up;
uniform float night;

varying vec3 vWorldPosition;
varying vec3 vSunDirection;
varying float vSunfade;
varying vec3 vBetaR;
varying vec3 vBetaM;
varying float vSunE;
varying vec2 vUv;

const float e = 2.71828182845904523536028747135266249775724709369995957;
const float pi = 3.141592653589793238462643383279502884197169;

const vec3 lambda = vec3( 680E-9, 550E-9, 450E-9 );
const vec3 totalRayleigh = vec3( 5.804542996261093E-6, 1.3562911419845635E-5, 3.0265902468824876E-5 );

const float v = 4.0;
const vec3 K = vec3( 0.686, 0.678, 0.666 );
const vec3 MieConst = vec3( 1.8399918514433978E14, 2.7798023919660528E14, 4.0790479543861094E14 );
const float cutoffAngle = 1.6110731556870734;
const float steepness = 1.5;
const float EE = 1000.0;

float sunIntensity( float zenithAngleCos ) {
    zenithAngleCos = clamp( zenithAngleCos, -1.0, 1.0 );
    return EE * max( 0.0, 1.0 - pow( e, -( ( cutoffAngle - acos( zenithAngleCos ) ) / steepness ) ) );
}

vec3 totalMie( float T ) {
    float c = ( 0.2 * T ) * 10E-18;
    return 0.434 * c * MieConst;
}

void main() {

    vec4 worldPosition = modelMatrix * vec4( position, 1.0 );
    vWorldPosition = worldPosition.xyz;

    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    gl_Position.z = gl_Position.w; // set z to camera.far

    vSunDirection = normalize( sunPosition );
    vSunE = sunIntensity( dot( vSunDirection, up ) ) + 0.1;
    vSunfade = 1.0 - clamp( 1.0 - exp( ( ( sunPosition.y - 200.0 ) / 450000.0 ) ), 0.0, 1.0 );
    float rayleighCoefficient = rayleigh - ( 1.0 * ( 1.0 - vSunfade ) );
    vBetaR = totalRayleigh * rayleighCoefficient;

    // mie coefficients
    vBetaM = totalMie( turbidity ) * mieCoefficient;

    vUv = uv;
    vUv = vec2(vUv.s, 1.0 - vUv.t);
}`;
export const fragmentShaderSky = `

varying vec3 vWorldPosition;
varying vec3 vSunDirection;
varying float vSunfade;
varying vec3 vBetaR;
varying vec3 vBetaM;
varying float vSunE;
varying vec2 vUv;

uniform vec4 colorsDay[10];
uniform vec4 colorsNight[10];
uniform float stepsDay[10];
uniform float stepsNight[10];
uniform float night;

uniform float mieDirectionalG;
uniform vec3 up;


const vec3 cameraPos = vec3(0.0, 0.0, 0.0);

// constants for atmospheric scattering
const float pi = 3.141592653589793238462643383279502884197169;

const float n = 1.0003;// refractive index of air
const float N = 2.545E25;// number of molecules per unit volume for air at 288.15K and 1013mb (sea level -45 celsius)

// optical length at zenith for molecules
const float rayleighZenithLength = 8.4E3;
const float mieZenithLength = 1.25E3;
// 66 arc seconds -> degrees, and the cosine of that
const float sunAngularDiameterCos = 0.989996676946448443553574619906976478926848692873900859324 / 0.996;

// 3.0 / ( 16.0 * pi )
const float THREE_OVER_SIXTEENPI = 0.05968310365946075;
// 1.0 / ( 4.0 * pi )
const float ONE_OVER_FOURPI = 0.07957747154594767;

float rayleighPhase(float cosTheta) {
    return THREE_OVER_SIXTEENPI * (1.0 + pow(cosTheta, 2.0));
}

float hgPhase(float cosTheta, float g) {
    float g2 = pow(g, 2.0);
    float inverse = 1.0 / pow(1.0 - 2.0 * g * cosTheta + g2, 1.5);
    return ONE_OVER_FOURPI * ((1.0 - g2) * inverse);
}


vec3 gradient(bool smoothed, int gradientType, in vec4 colors[10], in float steps[10], vec2 offset, vec2 morph, float angle) {
    float p;
    vec4 color = colors[0];
    float PI = 3.14159265359;

    vec2 m = morph / vUv.xy;
    vec2 rot = vec2(0.5 + m.x, m.y);
    vec2 dt = vec2(
    cos(angle) * rot.y - sin(angle) * rot.x,
    sin(angle) * rot.y + cos(angle) * rot.x
    );
    vec2 pt = (vUv - 0.5 + offset) / 2.0 + dt / 2.0; // (vUv - 0.5 + offset) / 2.0 + dt / 2.0;
    float t = dot(pt, dt) / dot(dt, dt);

    if (gradientType == 1) {
        t = distance (
        (vUv + morph) * 3.0,
        (vUv + offset) + 1.0
        ) + angle;
    } else if (gradientType == 2) {
        float polar = atan(
        vUv.x + morph.x - 0.5 + offset.x,
        vUv.y + morph.y - 0.5 + offset.y
        ) * -1.0;
        t = fract((angle / PI / -2.0) + 0.5 * (polar / PI));
    }

    if (smoothed) {
        for (int i = 1; i < 10; i++) {
            p = clamp((t - steps[i-1]) / (steps[i] - steps[i-1]), 0.0, 1.0);
            color = mix(color, colors[i], smoothstep(0.0, 1.0, p));
        }
    } else {
        for (int i = 1; i < 10; i++) {
            p = clamp((t - steps[i-1]) / (steps[i] - steps[i-1]), 0.0, 1.0);
            color = mix(color, colors[i], p);
        }
    }


    return color.xyz;
}





vec2 random2( vec2 p ) {
    return fract(sin(vec2(dot(p,vec2(12.1,1.7)), sin(dot(p,vec2(1.5,1.3))))) *323434.34344);
}

vec4 fromLinear(vec4 linearRGB)
{
    bvec4 cutoff = lessThan(linearRGB, vec4(0.0031308));
    vec4 higher = vec4(1.055)*pow(linearRGB, vec4(1.0/2.4)) - vec4(0.055);
    vec4 lower = linearRGB * vec4(12.92);

    return mix(higher, lower, cutoff);
}

vec4 toLinear(vec4 sRGB)
{
    bvec4 cutoff = lessThan(sRGB, vec4(0.04045));
    vec4 higher = pow((sRGB + vec4(0.055))/vec4(1.055), vec4(2.4));
    vec4 lower = sRGB/vec4(12.92);

    return mix(higher, lower, cutoff);
}

void main() {
    vec3 direction = normalize(vWorldPosition - cameraPos);
    float zenithAngle = acos(max(0.0, dot(up, direction)));
    float inverse = 1.0 / (cos(zenithAngle) + 0.15 * pow(93.885 - ((zenithAngle * 180.0) / pi), -1.253));
    float sR = rayleighZenithLength * inverse;
    float sM = mieZenithLength * inverse;
    vec3 Fex = exp(-(vBetaR * sR + vBetaM * sM));

    float cosTheta = dot(direction, vSunDirection);

    float rPhase = rayleighPhase(cosTheta * 0.5 + 0.5) / 200.0; // Remove shine "/ 200.0"
    vec3 betaRTheta = vBetaR * rPhase;

    float mPhase = hgPhase(cosTheta, mieDirectionalG);
    vec3 betaMTheta = vBetaM * mPhase;

    vec3 Lin = pow(vSunE * ((betaRTheta + betaMTheta) / (vBetaR + vBetaM)) * (1.0 - Fex), vec3(1.5));
    Lin *= mix(vec3(1.0), pow(vSunE * ((betaRTheta + betaMTheta) / (vBetaR + vBetaM)) * Fex, vec3(1.0 / 2.0)), clamp(pow(1.0 - dot(up, vSunDirection), 5.0), 0.0, 1.0));

    float theta = acos(direction.y);// elevation --> y-axis, [-pi/2, pi/2]
    float phi = atan(direction.z, direction.x);// azimuth --> x-axis [-pi/2, pi/2]
    vec2 uv = vec2(phi, theta) / vec2(2.0 * pi, pi) + vec2(0.5, 0.0);
    vec3 L0 = vec3(0.1) * Fex;

    float sundisk = smoothstep(sunAngularDiameterCos, sunAngularDiameterCos + 0.00002, cosTheta);
    L0 += (vSunE * 19000.0 * Fex) * sundisk;

    vec3 texColor = (Lin + L0) * 0.04 + vec3(0.0, 0.0003, 0.00075);
    vec3 retColor = pow(texColor, vec3(1.0 / (1.2 + (0.2 * vSunfade))));
    int gradientType = 0;
    vec2 morph = vec2(0.0);
    float angle = 0.0;
    bool smoothed = true;

    vec3 finalSkyDay = gradient(smoothed, gradientType, colorsDay, stepsDay, vec2(0.0, 0.0), morph, angle);
    vec3 finalSkyNight = gradient(smoothed, gradientType, colorsNight, stepsNight, vec2(0.0, -0.007), morph, angle);

    gl_FragColor = vec4(retColor + mix(finalSkyDay, finalSkyNight, night), 1.0);
}`;

export const vertexShaderVolcano = `
  varying vec2 vUv;
                    uniform vec3 viewVector;
                    varying float intensity;
                    
                    void main()
                    {
                      vUv = uv;
                      vUv = vec2(vUv.s, 1.0 - vUv.t);
                      
                      gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
                    }
  `;
export const fragmentShaderVolcano = `
  varying vec2 vUv;
  uniform float time;
  varying float intensity;

vec2 random2(vec2 st){
  st = vec2( dot(st,vec2(127.1,311.7)),
            dot(st,vec2(269.5,183.3)) );
  return -1.0 + 2.0*fract(sin(st)*43758.5453123);
}

float noise(vec2 st) {
  vec2 i = floor(st);
  vec2 f = fract(st);

  vec2 u = f*f*(3.0-2.0*f);

  return mix( mix( dot( random2(i + vec2(0.0,0.0) ), f - vec2(0.0,0.0) ), 
                    dot( random2(i + vec2(1.0,0.0) ), f - vec2(1.0,0.0) ), u.x),
              mix( dot( random2(i + vec2(0.0,1.0) ), f - vec2(0.0,1.0) ), 
                    dot( random2(i + vec2(1.0,1.0) ), f - vec2(1.0,1.0) ), u.x), u.y);
}

void main()
{
  

  vec3 orange = vec3(1., .45, 0.);
  vec3 yellow = vec3(1., 1., 0.);
  float iTime = time;
  
  vec2 iResolution = vec2(100.0, 100.0);
  // vec2 uv = (0.1 * gl_FragCoord.xy - iResolution.xy) / iResolution.y;
  // vec2 uv = gl_FragCoord.xy;
  vec2 uv = vUv * 4.0;

  uv.y += sin(iTime / 10.) * .1 - iTime / 10.;
  uv.x *= cos(iTime * 1. + uv.y * 4.) * .1 + .8;
  // uv += noise(uv * 2.25 + iTime / 5.);

  float col = smoothstep(.01,.2, noise(uv * 3.))
      + smoothstep(.01,.2, noise(uv * 6. + .5))
      + smoothstep(.01,.3, noise(uv * 7. + .2));

  orange.rg += .3 * sin(uv.y * 4. + iTime / 1.) * sin(uv.x * 5. + iTime / 1.);

  vec3 volcano = mix(yellow, orange, vec3(smoothstep(0., 1., col)));
  
  gl_FragColor =  vec4(volcano , 1.0);
}
  `;

export const vertexShaderLand = `
  varying vec2 vUv;

void main()
{
  vUv = uv;
  vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
  gl_Position = projectionMatrix * mvPosition;
}
  `;
export const fragmentShaderLand = `
   #ifdef GL_ES
  precision highp float;
  #endif
  uniform sampler2D tDay;
  uniform sampler2D tNight;
  uniform float opacity;

  varying vec2 vUv;


  void main(void)
  {
    vec3 c;
    float nightIntenciry = 1.0;
    vec4 Cday = texture2D(tDay, vUv);
    vec4 Cnight = texture2D(tNight, vUv);
    c = Cday.rgb * (1.0 - opacity) + Cnight.rgb * nightIntenciry * opacity;

    gl_FragColor= vec4(c, 1.0);
//    #include <encodings_fragment>

  }
  `;
