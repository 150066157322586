export const pointsDesktop = [
  [
    111.63694045357921, 28.368287207595852, -71.64084980676034, 16.972010573461304, 11.599305755263739,
    -14.087584845942036,
  ],

  [
    100.07630079989359, 3.0371649154217297, -61.44324175461119, 72.16985196536896, 4.728601308852368,
    -44.85976055111844,
  ],
  [
    93.47771813992804, -0.04821216491935356, -58.86250225283139, 68.18036880905805, 4.019979762394604,
    -44.38456846616898,
  ],

  [
    46.00648168277893, 1.4470194121101299, -44.593007046747566, 32.2098942288961, 2.3526411797680584,
    -33.74058301923184,
  ],
  [
    26.635707631847577, 3.8977141403185027, -50.3007359958579, 32.19640684667279, 2.3729574240745097,
    -33.82210188447396,
  ],
  [
    -10.863099888342, 16.894935892382428, -102.1660430374153, -5.305770566585319, 8.945366922824675,
    -36.410966955052906,
  ],

  [
    -31.728686780955314, 4.1729898723557355, -50.419225105525875, -29.562843175195034, 3.578670754228554,
    -30.53434973447358,
  ],
  [
    -47.13928630548653, 4.225987208541355, -40.14377623837599, -29.550067855012067, 3.582262227851964,
    -30.550549033426126,
  ],

  [
    -88.34913160980625, 12.104860254338364, 61.78768869537248, -35.40752303830854, 9.972911766929125,
    21.092398784899274,
  ],
  [
    -56.77958222210675, 9.868320650151798, 27.925798279824395, -29.48606726321809, 4.914098438441358,
    26.591882803593087,
  ],
  [-58.7934634338845, 4.519709651705162, 27.761443365371086, -29.63088339722196, 4.907176064810769, 26.595331414128218],

  [-13.18663192881445, 31.932013711407798, 43.03314145462531, 7.37539168222219, 6.43544783550813, 19.267463747369025],
  [36.4493788376601, 21.735204328153557, 43.58352031610568, 20.432971114525436, 6.730499191062375, 21.783235707219816],
];

export const pointsMobile = [
  [151.87249140580514, 30.47800432822148, -97.46123797310808, 0, 33.631465469885015, 0],

  [
    100.07630079989359, 3.0371649154217297, -61.44324175461119, 72.16985196536896, 4.728601308852368,
    -44.85976055111844,
  ],
  [
    93.47771813992804, -0.04821216491935356, -58.86250225283139, 68.18036880905805, 4.019979762394604,
    -44.38456846616898,
  ],

  [
    50.431099984383344, 1.0026473657533312, -49.16444757220065, 32.20675429155566, 6.886165200297758,
    -33.74284422692783,
  ],
  [
    38.987827710100035, 1.0792358039813488, -56.744937752751824, 32.22593666124661, 6.903434420013468,
    -33.74724310423805,
  ],
  [
    -10.863099888342, 16.894935892382428, -102.1660430374153, -5.305770566585319, 8.945366922824675,
    -36.410966955052906,
  ],

  [
    -32.24902735864653, 3.99731241086997, -49.73913642175837, -29.544376187149602, 5.026768152044164,
    -36.01065421652143,
  ],
  [
    -42.83998508634338, 3.1177671988944757, -38.198371832285744, -29.544650927379468, 5.029756757118496,
    -36.008008486325544,
  ],

  [
    -96.87186832613239, 8.381759893251688, -5.209769663567769, -35.390718506495325, 9.958446752266362,
    21.142367704601597,
  ],
  [
    -56.77958222210675, 9.868320650151798, 27.925798279824395, -29.48606726321809, 4.914098438441358,
    26.591882803593087,
  ],
  [
    -59.80192357111373, 4.014891597943505, 27.34437614476198, -29.629639391127075, 9.527921261166515,
    29.979926635112207,
  ],

  [-13.18663192881445, 31.932013711407798, 43.03314145462531, 7.37539168222219, 6.43544783550813, 19.267463747369025],
  [36.4493788376601, 21.735204328153557, 43.58352031610568, 20.432971114525436, 6.730499191062375, 21.783235707219816],
];
