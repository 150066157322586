import cs from 'classnames';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { useAnalytics } from '@/hooks/useAnalytics';

import Button from '../Button';
import { WytlandIcon } from '../SVG/WytlandIcon';
import Instagram from './Instagram';
import styles from './styles.module.scss';

interface PanelProps {
  className?: string;
  opened?: boolean;
  mode: 'day' | 'night';
  setIsPanelOpen: (value: boolean) => void;
  path: any;
  waitlist: boolean;
}

function useOutsideAlerter({ ref, setStatus, ...props }: any) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        props.setIsPanelOpen(false);
        setStatus('idle');
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

const subscribe = async (data: any) => {
  return await fetch('/api/waitlist', {
    method: 'POST',
    body: JSON.stringify(data),
  }).then((res) => {
    if (res.status === 200) {
      return res.json();
    }
    throw new Error('Something went wrong');
  });
};

const Waitlist = ({ status, setStatus, ...props }: any): JSX.Element => {
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors, isValid },
  } = useForm<any>({
    defaultValues: {
      instagram: '',
      email: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
    delayError: undefined,
  });
  const { mutate, isLoading } = useMutation(subscribe, {
    onSuccess: () => {
      setStatus('success');
    },
    onError: () => {
      setStatus('error');
    },
  });

  const analytics = useAnalytics();

  watch();
  const onSubmit = (data: any) => {
    mutate(data);
    analytics.identify(data.email, {
      email: data.email,
      instagram: data.instagram,
    });
    analytics.track('Join waitlist cta tapped', {
      key: 'website_waitlist_conversion',
      source: 'panel',
    });
  };

  if (status === 'success') {
    return (
      <div
        className='mx-auto flex h-screen w-full max-w-[343px] flex-col items-center justify-center
      tablet:max-w-[547px]'
      >
        <img className='w-[112px]' src='/images/smilingfaceheart.png' />
        <h1
          className={`text-${
            props.mode === 'day' ? 'black' : 'white'
          } my-[24px] text-center font-display text-[32px] font-bold
          tablet:text-[40px]`}
        >
          Wohoo!
        </h1>
        <p className={`text-${props.mode === 'day' ? 'black' : 'white'} mb-[24px] text-center`}>
          You have now been added to the waitlist. We{`'`}ll be in touch very soon.
        </p>
        <Button
          darkMode={props.mode === 'day'}
          className='font-medium'
          onClick={() => {
            setStatus('idle');
            props.setIsPanelOpen(false);
          }}
        >
          Thank you
        </Button>
      </div>
    );
  } else if (status === 'error') {
    return (
      <div
        className='mx-auto flex h-screen w-full max-w-[343px] flex-col items-center justify-center
      tablet:max-w-[547px]'
      >
        <img className='w-[112px]' src='/images/sorryface.png' />
        <h1
          className={`text-${
            props.mode === 'day' ? 'black' : 'white'
          } my-[24px] text-center font-display text-[32px] font-bold
          tablet:text-[40px]`}
        >
          Oops!
          <br />
          Something went wrong
        </h1>
        <Button
          darkMode={props.mode === 'day'}
          className='font-medium'
          onClick={() => {
            setStatus('idle');
          }}
        >
          Try again
        </Button>
      </div>
    );
  }

  return (
    <>
      <div
        className={cs([
          `text-left font-display font-bold text-[32px]  leading-[120%] text-${
            props.mode === 'day' ? 'black' : 'white'
          }  mx-[32px] mt-[120px]
                tablet:max-w-[355px] tablet:text-[48px] tablet:mx-[64px] tablet:mt-[136px]
                desktop:mb-[32px] desktop:text-left desktop:mx-[80px] desktop:text-[48px] desktop:mt-[max(20vh,_104px)]`,
        ])}
      >
        Join waitlist 🚀
      </div>
      <div
        className={`${props.opened ? 'relative' : 'hidden'} transition-all ${
          props.opened ? 'opacity-100' : 'opacity-0'
        } text-${props.mode === 'day' ? 'black' : 'white'}
              mx-[32px]
              tablet:mx-[64px]
              desktop:mx-[80px]`}
      >
        <p className='mt-8 text-lg leading-7'>
          As we are continuing to build our ecosystem for travellers, brands and travel creators, join our waitlist to
          be the first members of the community to access our products.
        </p>
        <form className='mt-5 flex flex-col' onSubmit={handleSubmit(onSubmit)}>
          <div className='mb-4'>
            <label className='mb-2 block text-sm' placeholder='Your best email'>
              Email*
            </label>
            <input
              {...register('email', {
                required: 'Please fill in your email address',
              })}
              className={`${
                props.mode === 'day'
                  ? 'bg-white focus:border-black focus:ring-black'
                  : 'bg-darkBluePale focus:border-white focus:ring-white'
              } h-[48px] w-full appearance-none rounded-[16px] border-2 outline-none focus:border-4 tablet:h-[56px] ${
                errors.email ? 'border-red-500' : props.mode === 'day' ? 'border-black' : 'border-white'
              } px-3 leading-tight shadow outline-none`}
              type='email'
              placeholder='Your best email'
            />
            {errors?.email && errors?.email.message && (
              <p className='mt-[8px] text-[14px] font-medium text-[#BE3300]'>{errors?.email.message as string}</p>
            )}
          </div>
          <div className=''>
            <label className='mb-2 block text-sm'>
              Instagram <span className='font-medium'>(for travel creators only)</span>
            </label>
            <input
              {...register('instagram', {
                required: false,
              })}
              className={`${
                props.mode === 'day'
                  ? 'border-black bg-white ring-black focus:border-black'
                  : 'border-white bg-darkBluePale ring-white focus:border-white'
              } mb-3 h-[48px] w-full appearance-none rounded-[16px] border-2 outline-none focus:border-4 tablet:h-[56px] ${
                errors.instagram ? 'border-red-500' : props.mode === 'day' ? 'border-black' : 'border-white'
              } pl-[151px] leading-tight shadow outline-none`}
              type='text'
              placeholder='Username'
            />
            <span className='absolute left-0 ml-10 mt-3 tablet:mt-4'>instagram.com/</span>
            <Instagram className='absolute -mt-12 ml-2 tablet:-mt-[52px]' />
            {errors?.instagram && errors?.instagram.message && (
              <p className='mt-[8px] text-[14px] font-medium text-[#BE3300]'>{errors?.instagram.message as string}</p>
            )}
          </div>
          <div className='mt-8 font-medium'>
            <Button
              darkMode={props.mode === 'day'}
              className='mb-10'
              disabled={isLoading || !isValid}
              icon={
                isLoading ? (
                  <svg
                    aria-hidden='true'
                    className={cs('h-6 w-6 animate-spin ', styles.spinner)}
                    viewBox='0 0 100 101'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                      fill='currentColor'
                    />
                    <path
                      d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                      fill='currentFill'
                    />
                  </svg>
                ) : null
              }
            >
              {!isLoading && 'Get early access'}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

const Panel = (props: PanelProps) => {
  const panelContainerRef = React.useRef<HTMLDivElement>(null);
  const panelFullContainerRef = React.useRef<HTMLDivElement>(null);
  const [status, setStatus] = React.useState('idle');
  const analytics = useAnalytics();

  const className = `absolute transition-all duration-1000 ${
    props.opened ? 'text-black' : '-translate-x-[100vw]'
  } top-0 left-0 desktopl:mx-auto ${props.className ?? ''}`;
  useOutsideAlerter({ ref: panelContainerRef, ...props, setStatus });

  useEffect(() => {
    if (panelFullContainerRef.current) {
      if (props.opened) {
        setTimeout(() => {
          if (panelFullContainerRef.current) panelFullContainerRef.current.style.overflow = 'auto';
        }, 1000);
      } else {
        panelFullContainerRef.current.style.overflow = 'hidden';
      }
    }
  }, [props.opened, panelFullContainerRef]);

  return (
    <div
      ref={panelFullContainerRef}
      data-lenis-prevent='true'
      className={cs(
        className,
        `${props.opened && ''} w-screen tablet:pr-[50%] z-10 h-[var(--vh,_100vh)] w-full ${
          props.mode === 'day' ? 'bg-pinkPale' : 'bg-bluePale'
        } tablet:bg-transparent`,
      )}
    >
      <div
        ref={panelContainerRef}
        className={`inline-block min-h-[var(--vh,_100vh)] w-full ${
          props.mode === 'day' ? 'bg-pinkPale' : 'bg-bluePale'
        }`}
      >
        <header
          className={cs(
            className,
            `sticky bg-gradient-to-b ${
              props.mode === 'day' ? 'from-pinkPale via-pinkPale' : 'from-bluePale via-bluePale'
            } to-transparent p-0 -mb-[120px] z-20 flex h-[72px] w-full items-center justify-between tablet:h-[80px] desktop:h-[88px] desktopl:h-[120px]`,
          )}
        >
          <WytlandIcon
            className={cs(
              'ml-[16px] desktop:ml-[80px] h-[16px] desktop:h-[24px]',
              props.mode === 'night' ? 'text-white' : 'text-black',
            )}
          />
          <Button
            darkMode={props.mode === 'day'}
            onClick={() => props.setIsPanelOpen(false)}
            className='mr-[16px] desktop:mr-[34px]'
            icon={
              props.mode === 'day' ? (
                <svg width='20' height='20' viewBox='0 0 20 20' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M12.1214 10L19.546 2.57542L17.4247 0.454102L10 7.87872L2.57542 0.454102L0.454102 2.57542L7.87872 10L0.454102 17.4247L2.57542 19.546L10 12.1214L17.4247 19.546L19.546 17.4247L12.1214 10Z'
                    fill='currentColor'
                  />
                </svg>
              ) : (
                <svg width='20' height='20' viewBox='0 0 20 20' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M12.1214 10L19.546 2.57542L17.4247 0.454102L10 7.87872L2.57542 0.454102L0.454102 2.57542L7.87872 10L0.454102 17.4247L2.57542 19.546L10 12.1214L17.4247 19.546L19.546 17.4247L12.1214 10Z'
                    fill='currentColor'
                  />
                </svg>
              )
            }
          ></Button>
        </header>
        {props.waitlist ? (
          <Waitlist {...props} status={status} setStatus={setStatus} />
        ) : (
          <>
            <div
              className={cs([
                `text-left font-display font-bold text-[32px] mt-[max(20vh,_104px)] leading-[38px] text-${
                  props.mode === 'day' ? 'black' : 'white'
                } max-w-[400px] mx-[32px] mt-[120px]
            tablet:max-w-full tablet:text-[40px]  tablet:mx-[64px] tablet:leading-[48px]
            desktop:mb-[32px] desktop:text-left desktop:leading-[58px] desktop:mx-[80px] desktop:text-[48px] desktop:mt-[120px] desktop:leading-[58px]`,
              ])}
              dangerouslySetInnerHTML={{ __html: props.path?.title ?? '' }}
            ></div>
            <div
              className={`panel relative ${props.mode === 'day' ? 'text-black' : 'text-white'}
              mx-[32px]
              tablet:mx-[64px]
              desktop:mx-[80px]`}
            >
              <p
                className={`my-8 text-lg leading-7 ${props.mode === 'day' ? 'text-black' : 'text-white'}`}
                dangerouslySetInnerHTML={{ __html: props.path?.description ?? '' }}
              ></p>
              {props.path?.cta
                ? props.path.cta.link && (
                    <Button
                      darkMode={props.mode === 'day'}
                      className='mb-10'
                      onClick={() => {
                        const contactUs = props.path?.cta?.link === '/contact-us';
                        window.open(
                          contactUs ? `contact-us?mode=${props.mode}` : props.path?.cta?.link,
                          contactUs ? '_self' : '_blank',
                        );

                        if (props.path?.cta)
                          analytics.track('Panel CTA tapped', {
                            key: props.path?.cta?.metricKey,
                            source: 'panel',
                          });
                      }}
                    >
                      {props.path.cta.text}
                    </Button>
                  )
                : null}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Panel;
