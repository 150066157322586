import cs from 'classnames';
import React, { useEffect } from 'react';

import Particle from './Particle';
import styles from './styles.module.scss';

const possibleEmoji = ['01.png', '02.png', '03.png', 'dot.png', 'dot.png', 'dot.png'];
const particles: any = [];

function updateParticles() {
  for (let i = 0; i < particles.length; i++) {
    particles[i].update();
  }

  for (let i = particles.length - 1; i >= 0; i--) {
    if (particles[i].lifeSpan < 0) {
      particles[i].die();
      particles.splice(i, 1);
    }
  }
}

const HeroVideo = () => {
  const [mouseEvent, setMouseEvent] = React.useState<React.MouseEvent<HTMLVideoElement, MouseEvent>>();
  const [hovering, setHovering] = React.useState(false);
  const videoRef = React.useRef<HTMLVideoElement>(null);
  let interval: NodeJS.Timeout;

  function addParticle(x: number, y: number, character: any) {
    if (particles.length > 20) return;
    const particle = new Particle();
    particle.init(x, y, character);
    particles.push(particle);
  }

  function loop() {
    requestAnimationFrame(loop);
    updateParticles();
  }

  useEffect(() => {
    loop();
  }, []);

  const onMouseMove = () => {
    const e = mouseEvent;
    if (hovering && e) {
      addParticle(e.clientX, e.clientY, possibleEmoji[Math.floor(Math.random() * possibleEmoji.length)]);
    }
  };

  useEffect(() => {
    function onDocumentMouseMove(e: MouseEvent) {
      const rect = (videoRef.current as HTMLElement).getBoundingClientRect();
      const screenWidth = rect.left + rect.width / 2;
      const screenHeight = rect.top + rect.height / 2;
      const $elems = [videoRef.current as HTMLElement];
      let validPropertyPrefix: any = '';
      const otherProperty = 'perspective(1000px)';
      const elemStyle = $elems[0].style;

      if (typeof elemStyle.webkitTransform === 'string') {
        validPropertyPrefix = 'webkitTransform';
      }

      const centroX = e.pageX - screenWidth;
      const centroY = screenHeight - (e.pageY + 13);
      const degX = centroX * 0.02;
      const degY = centroY * 0.02;
      let $elem;

      for (let i = 0; i < $elems.length; i++) {
        $elem = $elems[i];
        $elem.style[validPropertyPrefix] = otherProperty + 'rotateY(' + degX + 'deg)  rotateX(' + degY + 'deg)';
      }
    }

    window.addEventListener('mousemove', onDocumentMouseMove);

    return () => {
      window.removeEventListener('mousemove', onDocumentMouseMove);
    };
  }, [videoRef]);

  useEffect(() => {
    if (hovering) {
      interval = setInterval(() => {
        if (Math.random() > 0.7) return;
        onMouseMove();
      }, 200);
    }
    if (mouseEvent) {
      onMouseMove();
    }
    return () => clearInterval(interval);
  }, [hovering, mouseEvent]);

  return (
    <div
      className='order-none col-start-7 col-end-6 my-[56px] flex h-full w-full grow items-center 
            justify-center desktop:col-span-6 desktop:my-[0px]'
    >
      <video
        ref={videoRef}
        className={cs([
          'transition-all duration-300 z-10 aspect-[1/1] object-cover cursor-pointer rounded-[32px] max-w-[50vw] max-h-[50vw] tablet:aspect-[3/4.5] tablet:max-h-[40vw] desktop:my-[136px] desktop:w-[calc(50%_-_20px)] desktop:max-h-full',
          styles.glowing,
        ])}
        autoPlay
        playsInline
        loop
        muted
        src='/media/home.mp4'
        onMouseMove={(e) => {
          if (!e.target) return;
          setMouseEvent(e);
        }}
        onMouseEnter={() => {
          setHovering(true);
        }}
        onMouseOut={() => {
          setHovering(false);
          clearInterval(interval);
        }}
        onMouseLeave={() => {
          setHovering(false);
          clearInterval(interval);
        }}
      />
    </div>
  );
};

export default HeroVideo;
