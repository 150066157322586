const clamp = (a: number, min = 0, max = 1) => Math.min(max, Math.max(min, a));
const invlerp = (x: number, y: number, a: number) => clamp((a - x) / (y - x));

function easeOutCirc(x: number) {
  return Math.sqrt(1 - Math.pow(x - 1, 2));
}

function getRandomArbitrary(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

function applyProperties(target: any, properties: any) {
  for (const key in properties) {
    target.style[key] = properties[key];
  }
}

export default class Particle {
  life = 50;
  progress = 0;
  lifeSpan = this.life;
  velocity = { x: 0, y: 0, rotate: 0, scale: 0 };
  initialStyles = {
    position: 'absolute',
    display: 'block',
    pointerEvents: 'none',
    'z-index': '999',
    'will-change': 'transform',
    top: '0',
    left: '0',
  };

  position = { x: 0, y: 0 };
  element: any = null;

  init = (x: number, y: number, character: any) => {
    this.velocity = {
      x: getRandomArbitrary(-1, 1),
      y: getRandomArbitrary(0.5, 1) * 2,
      rotate: getRandomArbitrary(-1, 1),
      scale: getRandomArbitrary(1.2, 1.5),
    };

    this.position = { x: x - 30, y: y - 30 };

    this.element = document.createElement('img');
    this.element.src = '/images/' + character;
    this.element.width = 60;
    this.element.height = 60;

    applyProperties(this.element, this.initialStyles);
    this.update();

    document.body.appendChild(this.element);
  };

  update = () => {
    this.position.x += this.velocity.x;
    this.position.y -= this.velocity.y * 5;
    this.lifeSpan--;
    this.progress = 1 - this.lifeSpan / this.life;

    this.element.style.transform =
      'translate3d(' +
      this.position.x +
      'px,' +
      this.position.y +
      'px, 100px) ' +
      'scale(' +
      easeOutCirc(this.progress) * this.velocity.scale +
      ')' +
      'rotateZ(' +
      this.velocity.rotate * this.lifeSpan +
      'deg)';
    this.element.style.opacity =
      this.progress < 0.5 ? invlerp(0, 0.2, this.progress).toString() : invlerp(1, 0.8, this.progress).toString();
  };

  die = () => {
    this.element.parentNode.removeChild(this.element);
  };
}
