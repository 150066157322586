import cs from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

interface ButtonProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseDown?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseUp?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  icon?: React.ReactNode;
  glassMode?: boolean;
  darkMode?: boolean;
  outline?: boolean;
  disabled?: boolean;
}

const Button = (props: React.PropsWithChildren<ButtonProps>) => {
  const className = [
    `flex items-center justify-center
    disabled:cursor-not-allowed disabled:opacity-40
    ${styles.button}
    ${
      props.icon
        ? 'rounded-full desktop:min-h-[56px] tablet:min-h-[48px] min-h-[40px] desktop:min-w-[56px] tablet:min-w-[48px] min-w-[40px] leading-none'
        : 'rounded-full font-medium desktop:min-h-[56px] tablet:min-h-[48px] min-h-[40px] desktop:min-w-[56px] tablet:min-w-[48px] min-w-[40px] leading-none'
    }
    ${props.glassMode ? 'backdrop-blur-md ' : ''}
    ${(props.children && props.icon) || (props.children && !props.icon) ? 'px-6 py-2' : 'p-0'}`,
    props.darkMode
      ? styles.dark + (props.glassMode ? ' bg-black/70' : ' bg-black')
      : props.glassMode
      ? 'bg-white/70'
      : 'bg-white',
    props.outline ? styles.outline + ' border-[4px]' : '',
    props.className,
  ];

  return (
    <button
      onClick={props.onClick}
      onMouseDown={props.onMouseDown}
      onMouseUp={props.onMouseUp}
      className={cs(className)}
      disabled={props.disabled}
    >
      {props.icon && <div className={`${props.children ? 'mr-2' : ''}`}>{props.icon}</div>}
      {props.children}
    </button>
  );
};

export default Button;
