import { Vector3 } from 'three';

export const lights = [
  {
    distance: 25,
    initialIntensity: 2,
    intensity: 2,
    color: 0xffb65d,
    position: new Vector3(30.40183348191984, 4.538812610504189 + 2.5, 37.47007787716041),
  },
  {
    distance: 25,
    initialIntensity: 2,
    intensity: 2,
    color: 0xffd976,
    position: new Vector3(-32.932424110912294, -0.1033459494618384 + 2.5, 34.06931842334554),
  },
  {
    distance: 30,
    initialIntensity: 0.5,
    intensity: 0.5,
    color: 0x24ff00,
    position: new Vector3(-19.428318827903798, 6.775934175137136 + 2.5, -19.58919026313267),
  },
  {
    distance: 25,
    initialIntensity: 2,
    intensity: 2,
    color: 0xffb65d,
    position: new Vector3(32.17125701904297, 3.438889980316162 + 2.5, -18.893585205078125),
  },
  {
    distance: 50,
    initialIntensity: 2,
    intensity: 2,
    color: 0xff511a,
    position: new Vector3(4.9999999999999964, 34.59399626436762 + 2.5, 1.4535245212117687),
  },
  {
    distance: 15,
    initialIntensity: 2,
    intensity: 2,
    color: 0xffb65d,
    position: new Vector3(-49.89750476886641, -1.5, 47.66406779834365),
  },
];
