import * as THREE from 'three';
import particleFire from 'three-particle-fire';
import { useFrame, useThree } from '@react-three/fiber';
import { useEffect } from 'react';
import { PositionalAudio } from '@react-three/drei';

let fire;

const Fire = ({ muted }) => {
  const { camera, scene } = useThree();

  useEffect(() => {
    try {
      particleFire.install({ THREE });
    } catch (e) {
      console.log('particleFire already installed');
    }
  }, []);

  useEffect(() => {
    if (particleFire === undefined) return;
    const fireGeometry = new particleFire.Geometry(0.4, 3, 800);
    const fireMaterial = new particleFire.Material({
      color: 0xff2200,
    });
    fireMaterial.setPerspective(camera.fov, window.innerHeight);
    fire = new THREE.Points(fireGeometry, fireMaterial);
    fire.position.set(32.81637638173175, -0.6000099721321583, -34.039429662791974);
    scene.add(fire);
  }, [scene, camera.fov]);

  useFrame((state, delta) => {
    if (fire !== undefined) fire.material.update(delta);
  });

  return muted ? null : (
    <PositionalAudio
      autoplay
      loop
      url='/media/campfire.mp3'
      distance={0.2}
      position={[33.77580285000813, 0.6843292219981416, -34.924051700599286]}
    />
  );
};

export { Fire };
