import { Canvas } from '@react-three/fiber';
import Lenis from '@studio-freight/lenis';
import cs from 'classnames';
import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';
import { sRGBEncoding } from 'three';
import * as THREE from 'three';

import { useAnalytics } from '@/hooks/useAnalytics';

import Button from '../Button';
import Panel from '../Panel';
import { WytlandIcon } from '../SVG/WytlandIcon';
import AnimatedCamera from './AnimatedCamera';
import Island from './Island';
import Menu from './Menu';
import { pointsDesktop, pointsMobile } from './paths';
import styles from './styles.module.scss';

export default function Scene({
  loadScene,
  mode,
  setMode,
}: {
  loadScene: boolean;
  mode: 'day' | 'night';
  setMode: (mode: 'day' | 'night') => void;
}) {
  const [muted] = useState<boolean>(true);
  const scrollProgressBar = useRef<HTMLDivElement>(null);
  const [scrollBackwardBlocked, setScrollBackwardBlocked] = useState(true);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [pathHoverIndex, setPathHoverIndex] = useState<number | null>(null);
  const [lenis, setLenis] = useState<Lenis>();
  const [currentProgress, setCurrentProgress] = useState(0);
  const [lastProgress, setLastProgress] = useState(0);
  const [scrollDone, setScrollDone] = useState(false);
  const [visitedPaths, setVisitedPaths] = useState<number[]>([]);
  const [wailistOpen, setWailistOpen] = useState(false);
  const [menuOpened, setIsMenuOpen] = useState(false);

  const vh = window.innerHeight;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  window.addEventListener('resize', () => {
    const vh = window.innerHeight;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });

  const paths = [
    {
      sound: '/media/boat.mp3',
      start: 0.06,
      end: 0.15,
      topic: 'Generation Z',
      title: 'Gen Z will unify the world, shaping the future of your brand',
      tag: 'Digital Natives',
      cta: {
        text: 'Get in touch',
        link: '/contact-us',
        metricKey: 'website_contact_cta_tapped',
      },
      metricKey: 'website_explorers_cta_tapped',
      description:
        'Stay Ahead or Fall Behind. The old business playbook doesnt work anymore. Gen Z changing consumer and employee habits demand constant strategy reassessment. Adaptation is key in this new world.<br/><br/>Is your brand ready to captivate the attention and loyalty of Gen Z consumers? As a specialized Gen Z innovative studio, we are here to help you navigate the unique preferences and behaviors of this influential demographic. Our team of experts understands the pulse of Gen Z and can tailor cutting-edge strategies that resonate with their values and aspirations.<br/><br/>Contact us now to embark on a transformative partnership.',
      scrollIndex: -document.body.scrollHeight / 8,
    },
    {
      sound: '/media/campfire.mp3',
      start: 0.23,
      end: 0.3,
      topic: 'New tech Era',
      title: 'Gen Z are guiding the AI, Gaming, and Web3 Revolution',
      tag: 'New waves of technology',
      cta: {
        text: 'Visit our Paris Metaverse',
        link: 'https://www.spatial.io/s/WYTLAND-Paris-Metaverse-632af121b44c390001a1b0fb?share=4738915728530549221',
        metricKey: 'website_discord_cta_tapped',
      },
      metricKey: 'website_community_cta_tapped',
      description:
        'Research reveals that 85% of Gen Zers no longer use Google but prefer AI-powered virtual assistants for information retrieval, while blockchain adoption among this demographic has surged by 400% in two years. Traditional search methods no longer dominate this tech-savvy generation.<br/><br/> At our studio, we specialize in developing tailor-made immersive virtual worlds and AI digital characters that captivate Gen Z customers and talents alike, while enhancing your brands authenticity.',
      scrollIndex: -document.body.scrollHeight / 4,
    },
    {
      sound: '/media/bungalow.mp3',
      start: 0.45,
      end: 0.55,
      topic: 'Future of Work',
      title: 'Gen Z are shifting from traditional careers. Deal with it.',
      tag: 'Digital natives jobs',
      cta: {
        text: 'Join our Gen Z community',
        link: 'https://links.geneva.com/invite/55aa115d-dc5a-4aad-99bd-8357833bd57b',
        metricKey: 'website_geneva_cta_tapped',
      },
      metricKey: 'website_creators_cta_tapped',
      description:
        'Recruiting Gen Z talent has never been so hard. And no, they are not lazy as you think but they are waiting for you to change your approach as they prioritize meaningful work, work-life balance, and a positive company culture.<br/><br/>Does it sounds generalistic to you? If so, we definitely need to work together on new tools to engage them.',
      scrollIndex: -document.body.scrollHeight / 2.2,
    },
    {
      sound: '/media/beach.mp3',
      start: 0.68,
      end: 0.79,
      topic: 'Community',
      title: 'Brands: do you truly own your community right now?',
      tag: 'Community-led Products',
      cta: {
        text: 'Get in touch now',
        link: '/contact-us',
        metricKey: 'website_contact_cta_tapped',
      },
      metricKey: 'website_brands_cta_tapped',
      description:
        'For more than 10 years, you invested massively on social medias but the truth is, you get an audience but not a community. Worse, you do not own your data. At WYTLAND, we help brands ideate, design and build Gen Z community-led products.<br/><br/>As brands eagerly anticipate Gen Z, we’ve been overestimating their purchasing power as an independent demographic. As a result of the obstacles faced by Gen Z, they want to see real value in any products they consider buying. For brands, this means you have to go beyond pushing ads. You have to authentically connect with what you are offering and your community. <br/><br/>As an example, we believe brands entering 3D worlds will win the hearts and minds of Gen Z. With this new immersive gaming format, you can diversify your marketing budget to avoid spending millions on platforms that in turn keep all your data and value. <br/><br/>When you own your community, you’ll be able to find the secret sauce to create a deeper connection between your brand and Gen Z.',
      scrollIndex: -document.body.scrollHeight / 1.45,
    },
    {
      sound: '/media/jungle.mp3',
      start: 0.89,
      end: 0.97,
      topic: 'Sustainability',
      title: 'Doing good in the real world',
      tag: 'Sustainability',
      cta: false,
      metricKey: 'website_sustain_cta_tapped',
      description:
        'As we move towards a future where we embrace virtual worlds, we must not forget to sustain our real world, planet earth. At WYTLAND we help brands act in accordance with sustainability pillars in their technological choices<br/><br/>That’s why as a first attempt, we are developing on the Polygon blockchain and offsetting our digital footprint with the <a href="https://www.klimadao.finance/pledge/0x84d7499dd43b0c97711dd7c9a933ec15f3935f7c" target="_blank">KlimaDAO</a>. Besides, we have signed the <a href="https://cryptoclimate.org/accord/" target="_blank">Crypto Climate Accord</a> and will continue to propose causes for our community to vote on.<br/><br/>We always focus on impacting local communities and creators to tell their unique stories. Their voices must be heard.<br/><br/>We give the power to brands, content creators and local communities by providing an alternative to the large central platforms that own the destiny of these people so that we help them become independent and allow them to build a more sustainable business in the era to come.<br/><br/>This will take time, but we are convinced that we must start from Day 1.',
      scrollIndex: -document.body.scrollHeight / 1.13,
    },
  ];

  const points = window.innerWidth / window.innerHeight > 1 ? pointsDesktop : pointsMobile;

  useEffect(() => {
    if (lenis) {
      if (isPanelOpen) {
        lenis.stop();
      } else {
        lenis.start();
      }
    }
  }, [isPanelOpen]);

  useEffect(() => {
    if (loadScene) setMode('day');
  }, [loadScene]);

  useEffect(() => {
    if (visitedPaths.length) {
      const index = visitedPaths.length - 1;
      if (paths[index]) {
        analytics.track(`Journey ${index} step start`, {
          section: paths[index].topic,
          key: `website_journey_step${index}_started`,
          source: 'island',
        });

        if (visitedPaths.length === paths.length) {
          analytics.track('Journey completed', {
            section: 'Journey',
            key: 'website_journey_all_completed',
            source: 'island',
          });
        }
      }
    }
  }, [visitedPaths]);

  useEffect(() => {
    if (scrollProgressBar.current) {
      const canvas = document.querySelector('canvas');
      const len = new Lenis({
        duration: 1.2,
        easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
        direction: 'vertical',
        gestureDirection: 'vertical',
        smooth: true,
        mouseMultiplier: -0.5,
        smoothTouch: true,
        touchMultiplier: -10,
        infinite: true,
        wrapper: document.body,
        content: canvas as HTMLElement,
      });
      len.on('scroll', ({ progress }: { progress: number }) => {
        setCurrentProgress(progress);
      });
      setLenis(len);
    }
  }, [scrollProgressBar]);

  const analytics = useAnalytics();

  useEffect(() => {
    function raf(time: number) {
      if (lenis) lenis.raf(time);
      requestAnimationFrame(raf);
    }
    if (loadScene) {
      document.body.style.height = points.length * 500 + 100 + 'vh';
      document.body.style.overflow = 'hidden';
      if (menuOpened) setIsMenuOpen(false);
      if (currentProgress > 0.04) {
        setScrollDone(true);
      }

      if (!paths.some((path) => currentProgress >= path.start && currentProgress <= path.end) && !wailistOpen) {
        setIsPanelOpen(false);
      } else {
        const index = paths.findIndex((path) => currentProgress >= path.start && currentProgress <= path.end);
        if (!visitedPaths.includes(index)) {
          setVisitedPaths([...visitedPaths, index]);
        }
      }

      requestAnimationFrame(raf);

      if (scrollProgressBar.current) {
        if (
          scrollBackwardBlocked &&
          lastProgress >= 0 &&
          currentProgress >= 0.995 &&
          lastProgress <= 0.98 &&
          currentProgress >= 0.1
        ) {
          if (lenis) lenis.scrollTo(0.1);
          return;
        }
        scrollProgressBar.current.style.setProperty('--progress', currentProgress * 100 + '%');
        if (
          (scrollProgressBar.current && lastProgress > 0.98 && currentProgress < 0.2) ||
          (lastProgress < 0.1 && currentProgress > 0.98)
        ) {
          setLastProgress(0);
          if (scrollProgressBar.current.classList.contains('dark')) {
            scrollProgressBar.current.classList.remove('dark');
          } else {
            scrollProgressBar.current.classList.add('dark');
          }
          setMode(mode === 'day' ? 'night' : 'day');
          setScrollBackwardBlocked(false);
        }
        setLastProgress(currentProgress);
      }
    }
  }, [loadScene, mode, lenis, currentProgress]);

  return (
    <>
      <div
        className={`relative top-0 m-auto h-full w-full max-w-[1800px] transition-all duration-1000 ${
          loadScene ? 'z-10 opacity-100' : 'opacity-0'
        }`}
      >
        <div
          style={{
            width: 200,
            height: 200,
          }}
          className={cs([
            'transition text-center',
            styles.button,
            currentProgress > 0.04 || scrollDone ? 'opacity-0 pointer-events-none' : 'opacity-100',
          ])}
        >
          <div
            style={{
              position: 'absolute',
              width: 200,
              height: 200,
              left: 'calc(50% - 200px/2)',
              bottom: 0,
              cursor: 'initial',
              background: `radial-gradient(50% 50% at 50% 50%, ${
                mode === 'day'
                  ? 'rgba(0, 0, 0, 0.8) 67.19%, rgba(0, 0, 0, 0) 100%'
                  : 'rgba(255, 255, 255, 0.8) 67.19%, rgba(255, 255, 255, 0) 100%'
              }
            )`,
            }}
            className={`flex flex-col items-center justify-center text-sm leading-[17px] ${
              mode !== 'day' ? 'text-black' : 'text-white'
            }`}
          >
            <svg
              className='mb-4 hidden tablet:block'
              width='24'
              height='32'
              viewBox='0 0 24 32'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <rect
                x='1'
                y='1'
                width='22'
                height='30'
                rx='11'
                stroke={`${mode !== 'day' ? 'black' : 'white'}`}
                strokeWidth='2'
              />
              <rect
                className='animate-bounce'
                x='10'
                y='13'
                width='4'
                height='4'
                rx='2'
                fill={`${mode !== 'day' ? 'black' : 'white'}`}
              />
            </svg>
            <span className='animate-pulse'>
              Scroll
              <br />
              to explore
            </span>
            <svg
              className='mt-4 block tablet:hidden'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                className='animate-bounce'
                d='M12.9997 16.172L18.3637 10.808L19.7777 12.222L11.9997 20L4.22168 12.222L5.63568 10.808L10.9997 16.172V4H12.9997V16.172Z'
                fill={`${mode !== 'day' ? 'black' : 'white'}`}
              />
            </svg>
          </div>
        </div>
        <div
          className={cs([
            'absolute flex flex-col right-[16px] tablet:flex-row desktop:right-[64px] bottom-0 desktopl:right-[80px] pb-[16px] desktopl:pb-[32px]',
            styles.mediaButtons,
          ])}
        >
          {/* TODO: <Button
            darkMode={mode === 'day'}
            onClick={() => setMuted(!muted)}
            icon={
              muted ? (
                <svg width='20' height='20' viewBox='0 0 20 20' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M2.22183 0.807617L0.807617 2.22183L10 11.4142V11.55C9.41 11.21 8.73 11 8 11C5.79 11 4 12.79 4 15C4 17.21 5.79 19 8 19C10.21 19 12 17.21 12 15V13.4142L17.7782 19.1924L19.1924 17.7782L2.22183 0.807617Z'
                    fill='currentColor'
                  />
                  <path d='M12 5V7.75727L10 5.75727V0.999999H16V5H12Z' fill='currentColor' />
                </svg>
              ) : (
                <svg width='12' height='18' viewBox='0 0 12 18' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M6 0V10.55C5.41 10.21 4.73 10 4 10C1.79 10 0 11.79 0 14C0 16.21 1.79 18 4 18C6.21 18 8 16.21 8 14V4H12V0H6Z'
                    fill='currentColor'
                  />
                </svg>
              )
            }
          ></Button> */}
          <Button
            darkMode={mode === 'day'}
            onClick={() => {
              setMode(mode === 'day' ? 'night' : 'day');
              analytics.track('Switch Day/Night mode', {
                mode: mode === 'day' ? 'night' : 'day',
                key: 'website_daynight_switch_tapped',
                source: 'island',
              });
            }}
            className='mt-4 tablet:mt-0'
            icon={
              mode === 'day' ? (
                <svg width='22' height='22' viewBox='0 0 22 22' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M7.822 2.238C7.05219 3.90906 6.81303 5.77591 7.13672 7.58706C7.46041 9.39821 8.33141 11.0667 9.63238 12.3676C10.9333 13.6686 12.6018 14.5396 14.4129 14.8633C16.2241 15.187 18.0909 14.9478 19.762 14.178C18.768 18.654 14.775 22 10 22C4.477 22 0 17.523 0 12C0 7.225 3.346 3.232 7.822 2.238ZM16.164 2.291L17 2.5V3.5L16.164 3.709C15.8124 3.79693 15.4913 3.97875 15.235 4.23503C14.9788 4.4913 14.7969 4.8124 14.709 5.164L14.5 6H13.5L13.291 5.164C13.2031 4.8124 13.0212 4.4913 12.765 4.23503C12.5087 3.97875 12.1876 3.79693 11.836 3.709L11 3.5V2.5L11.836 2.291C12.1874 2.20291 12.5083 2.02102 12.7644 1.76475C13.0205 1.50849 13.2021 1.18748 13.29 0.836L13.5 0H14.5L14.709 0.836C14.7969 1.1876 14.9788 1.5087 15.235 1.76497C15.4913 2.02125 15.8124 2.20307 16.164 2.291ZM21.164 7.291L22 7.5V8.5L21.164 8.709C20.8124 8.79693 20.4913 8.97875 20.235 9.23503C19.9788 9.4913 19.7969 9.8124 19.709 10.164L19.5 11H18.5L18.291 10.164C18.2031 9.8124 18.0212 9.4913 17.765 9.23503C17.5087 8.97875 17.1876 8.79693 16.836 8.709L16 8.5V7.5L16.836 7.291C17.1876 7.20307 17.5087 7.02125 17.765 6.76497C18.0212 6.5087 18.2031 6.1876 18.291 5.836L18.5 5H19.5L19.709 5.836C19.7969 6.1876 19.9788 6.5087 20.235 6.76497C20.4913 7.02125 20.8124 7.20307 21.164 7.291Z'
                    fill='currentColor'
                  />
                </svg>
              ) : (
                <svg width='22' height='22' viewBox='0 0 22 22' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M11 17C9.4087 17 7.88258 16.3679 6.75736 15.2426C5.63214 14.1174 5 12.5913 5 11C5 9.4087 5.63214 7.88258 6.75736 6.75736C7.88258 5.63214 9.4087 5 11 5C12.5913 5 14.1174 5.63214 15.2426 6.75736C16.3679 7.88258 17 9.4087 17 11C17 12.5913 16.3679 14.1174 15.2426 15.2426C14.1174 16.3679 12.5913 17 11 17ZM10 0H12V3H10V0ZM10 19H12V22H10V19ZM2.515 3.929L3.929 2.515L6.05 4.636L4.636 6.05L2.515 3.93V3.929ZM15.95 17.364L17.364 15.95L19.485 18.071L18.071 19.485L15.95 17.364ZM18.071 2.514L19.485 3.929L17.364 6.05L15.95 4.636L18.071 2.515V2.514ZM4.636 15.95L6.05 17.364L3.929 19.485L2.515 18.071L4.636 15.95ZM22 10V12H19V10H22ZM3 10V12H0V10H3Z'
                    fill='currentColor'
                  />
                </svg>
              )
            }
          ></Button>
        </div>
        <div className={cs([`absolute transition left-[16px] tablet:left-[80px] mx-auto`])}>
          <div
            className={cs(
              `transition-all absolute pointer-events-none ${
                mode === 'day' &&
                paths.some((path) => currentProgress >= path.start && currentProgress <= path.end) &&
                !isPanelOpen
                  ? 'opacity-100'
                  : 'opacity-0'
              }`,
              styles.overlay,
            )}
          />
          {paths.map((path, i) => (
            <div
              key={i}
              className={`fixed max-w-lg transition ${
                currentProgress > path.start && currentProgress < path.end
                  ? 'opacity-100'
                  : 'pointer-events-none opacity-0'
              }`}
            >
              <div
                className={cs([
                  `text-left transition-all font-display font-bold text-[32px] max-w-[342px] col-span-4 top-0 mt-[88px] text-${
                    mode === 'day' ? 'black' : 'white'
                  }
                  ${isPanelOpen ? 'opacity-0' : 'opacity-100'}
                tablet:max-w-full tablet:mt-[max(20vh,_136px)]
                desktop:mb-[32px] desktop:text-left leading-[120%] desktop:text-[48px]`,
                ])}
              >
                <span className='mb-[16px] rounded-[8px] bg-pink px-[8px] py-[5px] text-[14px] font-medium text-white'>
                  {path?.tag}
                </span>
                <div dangerouslySetInnerHTML={{ __html: path.title }} />
              </div>
              <Button
                className={cs([
                  `relative transition-all mt-8 p-6 text-lg desktop:font-medium z-10
                   ${isPanelOpen ? 'opacity-0' : 'opacity-100'}`,
                  styles.readMoreButton,
                ])}
                darkMode={mode === 'day'}
                onClick={() => {
                  setWailistOpen(false);
                  setIsPanelOpen(!isPanelOpen);
                  analytics.track(path.topic + ' Pane view', {
                    key: path.metricKey,
                  });
                }}
              >
                Discover more
              </Button>
            </div>
          ))}
        </div>
        <header
          className={`absolute z-10 flex h-[72px] w-full items-center justify-between tablet:h-[80px] desktop:h-[88px] desktopl:h-[120px] ${
            isPanelOpen ? 'pointer-events-none' : ''
          }`}
        >
          <WytlandIcon
            className={cs(
              'transition duration-1000 ml-[16px] desktop:ml-[80px] h-[16px] desktop:h-[24px]',
              mode === 'night' ? 'text-white' : 'text-black',
              isPanelOpen ? 'opacity-0' : 'opacity-100',
            )}
          />
          <div
            className={`${
              isPanelOpen ? 'hidden desktop:flex' : 'flex'
            } mr-[16px] items-center tablet:right-[12px] desktop:top-[32px] desktop:mr-[64px] desktopl:top-[32px] desktopl:mr-[80px]`}
          >
            <Link
              className='hidden tablet:flex'
              href='https://discord.gg/mtUhx3PRCN'
              target={'_blank'}
              onClick={() =>
                analytics.track('Discord community cta tapped', { key: 'website_discord_cta_tapped', source: 'header' })
              }
            >
              <Button
                outline
                darkMode={mode === 'day'}
                className='text-xl font-medium'
                icon={
                  <svg
                    width='22'
                    height='17'
                    viewBox='0 0 22 17'
                    fill='currentColor'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M18.6361 1.42372C17.1907 0.74903 15.6648 0.27038 14.0973 -2.90461e-08C13.8828 0.39093 13.6888 0.793144 13.5159 1.20496C11.8463 0.948447 10.1484 0.948447 8.47881 1.20496C8.30587 0.793187 8.1118 0.390977 7.8974 -1.62718e-08C6.32897 0.272663 4.80205 0.75245 3.35518 1.42725C0.482757 5.76017 -0.29591 9.98547 0.0934235 14.1508C1.77558 15.418 3.6584 16.3817 5.66003 17C6.11074 16.382 6.50956 15.7263 6.85226 15.04C6.20135 14.7921 5.57311 14.4863 4.9748 14.1261C5.13227 14.0097 5.28627 13.8897 5.43508 13.7732C7.17601 14.608 9.07613 15.0408 11 15.0408C12.9238 15.0408 14.8239 14.608 16.5648 13.7732C16.7154 13.8985 16.8694 14.0185 17.0251 14.1261C16.4257 14.4869 15.7963 14.7933 15.1442 15.0417C15.4865 15.7277 15.8853 16.3829 16.3364 17C18.3398 16.3841 20.224 15.4209 21.9065 14.1526C22.3633 9.32213 21.1261 5.13564 18.6361 1.42372ZM7.34541 11.5891C6.26047 11.5891 5.36414 10.5853 5.36414 9.35035C5.36414 8.1154 6.22932 7.10274 7.34195 7.10274C8.45458 7.10274 9.344 8.1154 9.32496 9.35035C9.30593 10.5853 8.45112 11.5891 7.34541 11.5891ZM14.6545 11.5891C13.5678 11.5891 12.675 10.5853 12.675 9.35035C12.675 8.1154 13.5401 7.10274 14.6545 7.10274C15.7689 7.10274 16.6514 8.1154 16.6323 9.35035C16.6133 10.5853 15.7602 11.5891 14.6545 11.5891Z'
                      fill='currentColor'
                    />
                  </svg>
                }
              />
            </Link>
            <Button
              onClick={() => {
                analytics.track('Join Waitlist cta tapped', {
                  metricKey: 'website_joinwaitlist_cta_tapped',
                });
                window.location.href = 'https://www.wytland.co/contact-us';
              }}
              outline
              darkMode={mode === 'day'}
              className='ml-5 hidden text-xl font-medium tablet:flex'
            >
              Contact
            </Button>
            <Menu
              menuOpened={menuOpened}
              setIsMenuOpen={setIsMenuOpen}
              mode={mode}
              lenis={lenis}
              setIsPanelOpen={setIsPanelOpen}
              setWaitlistOpen={setWailistOpen}
            />
          </div>
        </header>
        <div
          className={cs(
            styles.pathButtons,
            `${
              menuOpened ? 'z-0' : ''
            } absolute right-[26px] flex z-10 w-0 h-full tablet:right-[33px] desktopl:right-[76px] flex-col items-end justify-center`,
          )}
        >
          {paths.map((path, i) => (
            <div key={i} className={cs(styles.pathButtonContainer, 'flex items-center justify-end w-max')}>
              <div
                className={cs(
                  styles.bubble,
                  `${
                    pathHoverIndex === i ? 'w' : 'opacity-0'
                  } cursor-default relative mr-2 flex h-[40px] items-center rounded-full px-4 text-sm transition-all duration-300 ${
                    mode === 'day' ? 'bg-black text-white' : 'bg-white text-black'
                  }`,
                )}
              >
                {path.topic}
                <svg
                  className='absolute -right-[4px]'
                  width='4'
                  height='6'
                  viewBox='0 0 4 6'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M4 3L-2.38498e-08 6L2.38419e-07 -1.74846e-07L4 3Z'
                    fill={mode === 'day' ? 'black' : 'white'}
                  />
                </svg>
              </div>
              <div
                className={cs(
                  styles.pathButtonWrapper,
                  'flex h-[24px] w-[24px] cursor-pointer items-center justify-center',
                )}
                onClick={() => {
                  lenis?.scrollTo(path.scrollIndex, {
                    duration: 3,
                    easing: (x) => (x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2),
                  });
                }}
                onMouseEnter={() => setPathHoverIndex(i)}
                onMouseLeave={() => setPathHoverIndex(null)}
                onTouchEnd={() => setPathHoverIndex(null)}
                onTouchCancel={() => setPathHoverIndex(null)}
                onTouchEndCapture={() => setPathHoverIndex(null)}
                onTouchCancelCapture={() => setPathHoverIndex(null)}
              >
                <div
                  className={cs([
                    styles.pathButton,
                    'w-[24px] h-[24px]',
                    mode === 'day' ? 'bg-black' : 'bg-white',
                    currentProgress > path.start && currentProgress < path.end ? styles.pathButtonActive : '',
                  ])}
                >
                  <span className={cs([styles.pathInsideButton, mode === 'day' ? 'bg-white' : 'bg-bluePale'])}></span>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Panel
          opened={isPanelOpen}
          mode={mode}
          waitlist={wailistOpen}
          setIsPanelOpen={setIsPanelOpen}
          path={paths.find((path) => currentProgress >= path.start && currentProgress <= path.end)}
        />
      </div>
      <div
        className={`fixed top-0 h-[var(--vh,_100vh)] transition-all duration-1000 ${
          isPanelOpen ? 'left-[30%]' : 'left-0'
        } m-auto h-screen w-screen`}
      >
        <Canvas
          gl={{
            antialias: true,
            outputEncoding: sRGBEncoding,
            powerPreference: 'high-performance',
            toneMapping: THREE.NoToneMapping,
          }}
          style={{ overflow: 'none', touchAction: 'auto' }}
        >
          {lenis && (
            <AnimatedCamera
              points={points}
              visibleIsland={loadScene}
              progress={currentProgress}
              lastProgress={lastProgress}
            />
          )}
          <Island mode={mode} visibleIsland={loadScene} scrollProgress={currentProgress} muted={muted} />
        </Canvas>
      </div>
      <div
        ref={scrollProgressBar}
        className={cs(
          styles.progress,
          `transition-all ${!isPanelOpen && 'z-10'} ${loadScene ? 'opacity-100' : 'opacity-0'}`,
        )}
      ></div>
    </>
  );
}
