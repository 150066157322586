'use client';
import { useProgress } from '@react-three/drei';
import cs from 'classnames';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import ClientOnly from '@/components/ClientOnly/ClientOnly';
import HeroVideo from '@/components/HeroVideo';
import LoadingButton from '@/components/LoadingButton';
import MixedGradient from '@/components/MixedGradient';
import Scene from '@/components/Scene';
import { WytlandIcon } from '@/components/SVG/WytlandIcon';
import { useAnalytics } from '@/hooks/useAnalytics';
import useLocalStorage from '@/hooks/useLocalStorage';

const Home = () => {
  const [loadScene, setLoadScene] = useState(false);

  const [mode, setMode] = useLocalStorage<'day' | 'night'>('mode', 'night');

  const analytics = useAnalytics();
  const { progress } = useProgress();
  const [realProgress, setRealProgress] = React.useState(0);
  const router = useRouter();

  useEffect(() => {
    if (router.query.initialized && progress >= 100) {
      setTimeout(() => setLoadScene(true), 300);
    }
  }, [router, progress]);

  useEffect(() => {
    if (progress > realProgress) {
      setRealProgress(progress);
    }
  }, [progress]);

  useEffect(() => {
    document.body.style.backgroundColor = mode === 'day' ? 'rgb(241, 203, 232)' : 'rgb(19, 34, 65)';
  }, [mode]);

  return (
    <>
      <Head>
        <title>WYTLAND - The Gen Z gaming and AI studio</title>
        <meta
          name='description'
          content='We are an innovative studio leveraging AI and Gaming to build the next Gen Z businesses.'
        />
        <meta name='theme-color' content='#000F26' />
        <meta name='robots' content='index, follow' />
        <meta name='viewport' content='width=device-width' />
        <meta property='og:title' content='WYTLAND - The next-gen innovative studio' />
        <meta
          property='og:description'
          content='We are an innovative studio leveraging AI and Gaming to build the next Gen Z businesses.'
        />
        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://wytland.co/' />
        <meta property='og:image' content='/images/opengraph.png' />

        <meta name='twitter:card' content='summary_large_image' />
        <meta property='twitter:domain' content='https://wytland.co' />
        <meta name='twitter:title' content='WYTLAND - The GenZ gaming and AI studio' />
        <meta
          name='twitter:description'
          content='We are an innovative studio leveraging AI and Gaming to build the next Gen Z businesses.'
        />
        <meta name='twitter:image' content='/images/opengraph.png' />

        <meta name='viewport' content='width=device-width' />
        <link rel='apple-touch-icon' sizes='180x180' href='/images/apple-touch-icon.png' />
        <link rel='icon' type='image/png' sizes='32x32' href='/images/favicon-32x32.png' />
        <link rel='icon' type='image/png' sizes='16x16' href='/images/favicon-16x16.png' />
        <link rel='manifest' href='/site.webmanifest' />
      </Head>
      <div className={`fixed h-[var(--vh,_100vh)] w-full ${mode === 'day' ? 'bg-pinkPale' : 'bg-bluePale'}`}>
        <div
          className={`absolute z-10 min-h-full min-w-full overflow-auto bg-darkBlue transition-all duration-[2000ms] ${
            loadScene ? 'opacity-0' : 'opacity-100'
          }`}
          data-lenis-prevent
        >
          <MixedGradient
            className={cs(loadScene ? '-top-[200vh]' : '-top-[100vh]', 'transition-all duration-[2000ms]')}
          />
          <div
            className={`relative z-10 mx-auto w-[calc(100%_-_32px)] max-w-[1800px] transition-all duration-[1000ms] 
          desktop:w-[calc(100%_-_128px)]
          desktopl:w-[calc(100%_-_160px)] ${loadScene ? 'opacity-0' : 'opacity-100'}`}
          >
            <div
              className='absolute flex h-[72px] w-full items-center justify-between tablet:h-[80px]
          desktopl:h-[120px]'
            >
              <div className='logo'>
                <WytlandIcon className='h-[24px]' />
              </div>
            </div>

            <div
              className='flex h-screen flex-col
            justify-between desktop:grid desktop:grid-cols-12 desktop:items-start
            desktop:gap-[32px] desktopl:gap-[40px]'
            >
              <div
                className='min-h-auto order-1 col-start-1 col-end-6 flex pb-[128px] text-center
            tablet:items-center tablet:px-[calc(12.5%)]
            desktop:order-none desktop:col-span-6 desktop:min-h-[100vh] desktop:items-center desktop:p-0 desktop:text-left
            '
              >
                <div className='w-full desktop:my-[136px]'>
                  <h1
                    className='m-0 mb-[16px] font-display text-[40px] font-bold leading-[120%]
                tablet:text-[64px]
                desktop:mb-[24px]
                desktopl:text-[96px]'
                  >
                    Welcome 👋
                    <br />
                  </h1>{' '}
                  <p
                    className='m-0 mb-[32px] text-[16px] leading-[160%]
                tablet:mb-10 tablet:text-[20px] desktopl:mb-12 desktopl:text-[24px]'
                  >
                    We are an innovative studio helping iconic brands to leverage AI, Blockchain and Gaming to build the
                    next Gen Z businesses that will impact the world.
                  </p>
                  <LoadingButton
                    progress={realProgress}
                    onClick={() => {
                      analytics.track('Hero page CTA clicked', {
                        key: 'website_hero_cta_tapped',
                        source: 'home',
                        page: window.location.pathname,
                        type: 'event',
                      });
                      setLoadScene(true);
                    }}
                  />
                </div>
              </div>
              <HeroVideo />
            </div>
          </div>
        </div>
        <ClientOnly>
          <Scene mode={mode} setMode={setMode} loadScene={loadScene} />
        </ClientOnly>
      </div>
    </>
  );
};

export default Home;
