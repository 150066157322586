import { PerspectiveCamera } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import gsap from 'gsap';
import { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import * as THREE from 'three';
import { Vector3 } from 'three';

import { useDeviceOrientation } from '@/hooks/useDeviceOrientation';

const parallax = 0.07;
let shiftX = 0;
let shiftY = 0;
let mouse = new THREE.Vector2(0, 0);
let target;
const clamp = (a: number, min = 0, max = 1) => Math.min(max, Math.max(min, a));

const AnimatedCamera = ({
  points,
  visibleIsland,
  lastProgress,
  progress,
}: {
  lastProgress: number;
  progress: number;
  points: number[][];
  visibleIsland: boolean;
}) => {
  const cameraRef = useRef<any>();
  const set = useThree(({ set }) => set);
  const gl = useThree(({ gl }) => gl);
  const rise = { x: 50, angle: 45 };
  const [, setCurrentCameraPosition] = useState<THREE.Vector3>();
  const [currentTargetPosition, setCurrentTargetPosition] = useState<THREE.Vector3>();
  const { camera } = useThree();
  const screenRatio = window.innerWidth / window.innerHeight;
  const targetObject = new THREE.Object3D();

  const [initialOrientation, setInitialOrientation] = useState<any>({ x: 0, y: 0 });
  const { orientation, screenOrientation, enabled: orientationEnabled, requestAccess } = useDeviceOrientation();

  const setObjectQuaternion = (function () {
    const zee = new Vector3(0, 0, 1);
    const euler = new THREE.Euler();
    const q0 = new THREE.Quaternion();
    const q1 = new THREE.Quaternion(-Math.sqrt(0.5), 0, 0, Math.sqrt(0.5));
    return function (quaternion: any, alpha: number, beta: number, gamma: number, orient: number) {
      euler.set(beta, alpha, -gamma, 'YXZ');
      quaternion.setFromEuler(euler);
      quaternion.multiply(q1);
      quaternion.multiply(q0.setFromAxisAngle(zee, -orient));
    };
  })();

  const windowHalf = new THREE.Vector2(window.innerWidth / 2, window.innerHeight / 2);
  const curvePosition = new THREE.CatmullRomCurve3(
    points.map((p) => new THREE.Vector3(p[0], p[1], p[2])),
    true,
    'catmullrom',
  );
  const curveTarget = new THREE.CatmullRomCurve3(
    points.map((p) => new THREE.Vector3(p[3], p[4], p[5])),
    true,
    'centripetal',
  );

  useEffect(() => {
    gl.setSize(window.innerWidth, window.innerHeight);
    set({ camera: cameraRef.current });
  }, [cameraRef.current]);

  const getCameraPosition = () => {
    const currentCameraPosition = curvePosition.getPoint(lastProgress);
    const currentTargetPosition = curveTarget.getPoint(lastProgress);

    const endTargetPosition = currentTargetPosition.clone();
    const distance = currentCameraPosition.distanceTo(endTargetPosition);
    endTargetPosition.y = rise.x + distance / Math.tan((rise.angle * 3.1416) / 180);

    return {
      currentCameraPosition,
      currentTargetPosition,
      endTargetPosition,
    };
  };

  const onMouseMove = (e: MouseEvent) => {
    mouse = new THREE.Vector2(
      (e.clientX - windowHalf.x) / window.innerWidth,
      (e.clientY - windowHalf.y) / window.innerHeight,
    );
  };

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove);
    return () => {
      document.removeEventListener('mousemove', () => onMouseMove);
    };
  }, []);

  useEffect(() => {
    if (!cameraRef || !progress) return;
    lastProgress = progress;
    target = curveTarget.getPoint(progress);
    setCurrentTargetPosition(target);
  }, [progress]);

  useEffect(() => {
    const initialTarget = getCameraPosition();
    camera.position.set(111.63694, 50, -71.64085);

    setCurrentCameraPosition(camera.position);
    setCurrentTargetPosition(initialTarget.endTargetPosition);

    if (!visibleIsland) {
      return;
    }

    const _c = getCameraPosition();
    (camera.position as any).p = 0;
    gsap.to(camera.position, {
      duration: 2.5,
      x: _c.currentCameraPosition.x,
      y: !visibleIsland ? _c.currentCameraPosition.y : rise.x,
      z: _c.currentCameraPosition.z,
      p: 1,
      ease: 'power2.inOut',
      onUpdate: () => {
        let p = (camera.position as any).p;
        p = 1 - p;
        const newCurrentTargetPosition = new Vector3().lerpVectors(_c.currentTargetPosition, _c.endTargetPosition, p);
        setCurrentTargetPosition(newCurrentTargetPosition);
      },
      onComplete: () => {
        visibleIsland = true;
      },
    });
    requestAccess();
  }, [visibleIsland]);

  useEffect(() => {
    const update = () => {
      requestAnimationFrame(update);

      if (camera) {
        const point = curvePosition.getPoint(lastProgress);
        camera.position.set(point.x, point.y, point.z);

        if (isMobile && orientationEnabled) {
          const alpha = orientation?.alpha ? THREE.MathUtils.degToRad(orientation.alpha) : 0;
          const beta = orientation?.beta ? THREE.MathUtils.degToRad(clamp(orientation.beta, 0, 180)) : 0;
          const gamma = orientation?.gamma ? THREE.MathUtils.degToRad(orientation.gamma) : 0;
          const orient = screenOrientation ? THREE.MathUtils.degToRad(screenOrientation) : 0;

          setObjectQuaternion(targetObject.quaternion, alpha, beta, gamma, orient);

          if (targetObject.rotation.y !== 0) {
            const x = -targetObject.rotation.y * 2;
            const y = -targetObject.rotation.x;

            if (initialOrientation == null) {
              setInitialOrientation({ x, y });
            } else {
              mouse.x = x - initialOrientation.x;
              mouse.y = y - initialOrientation.y;
            }
          }
        }

        if (currentTargetPosition && camera) {
          targetObject?.position.set(currentTargetPosition?.x, currentTargetPosition?.y, currentTargetPosition?.z);
          targetObject?.lookAt(camera.position);
        }

        if (isMobile) {
          const target = curveTarget.getPoint(lastProgress);
          camera.lookAt(target.x, target.y, target.z);
        } else {
          shiftX += (mouse.x - shiftX) * parallax;
          shiftY += (mouse.y - shiftY) * parallax;
          const distance = targetObject.position.distanceTo(camera.position);
          targetObject.translateX(((shiftX * distance) / 10) * screenRatio);
          targetObject.translateY((-shiftY * distance) / 10);
          camera.lookAt(targetObject.position);
        }
      }
    };
    update();
  }, [camera, curvePosition]);

  return <PerspectiveCamera ref={cameraRef} fov={45} near={1} far={100000} />;
};

export default AnimatedCamera;
